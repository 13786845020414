import { CloseIcon, ExportButton, SearchBar, Table } from "common";
import React, { useEffect, useRef, useState } from "react";
import styles from "./TableWIthSearchInputPopup.module.scss";
import { IList, IPopup } from "common/interfaces";
import Popup from "./Popup";
import { useAppSelector } from "reducers/Hooks";
import { displayMiniFeedback } from "utils/helpers";
import { SearchBarRef } from "common/SearchBar";
import { TableRef } from "common/Table";
import InvitationHandler from "actions/InvitationHandler";
import Observer, { EVENTS } from "classes/Observer";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import Analytics, { ITracking } from "classes/Analytics";
import { formatAPIDate, formatAPIPhone } from "utils/formatters";

const InvitationStatusPopup: React.FC<IPopup> = () => {
  const message = useAppSelector(state => state.popUp.messagePassing);
  const [keyword, setKeyword] = useState<string>("");
  const searchBarRef = useRef<SearchBarRef>(null);
  const tableRef = useRef<TableRef>(null);
  const observer = Observer.useObserver(EVENTS.INVITATION_UPDATED);
  const { merchantSlug } = useParams();
  const [preventPagination, setPreventPagination] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [tableRows, setTableRows] = useState([]);

  useEffect(() => {
    tableRef?.current?.reloadData();
  }, [observer]);

  useEffect(() => {
    if (keyword) {
      Analytics.track({ experience: "portal", screen: message === "APPLICATIONS" ? (merchantSlug ? "merchants_applications_invitation_status" : "applicant_invitation_status") : "merchant_invitation_status", object: "search", action: "initiated" } as ITracking);
    }
  }, [keyword]);

  let headers = message === "APPLICATIONS" ?
    [
      { label: "Email", value: "email", size: 10 },
      { label: "Phone", value: "phone_number", size: 5 },
      { label: "Sent", value: "created_at", size: 5 },
      { label: "Status", value: "status", size: 4 },
      { label: "Actions", value: "actions", size: 7, preventSorting: true }
    ] : [
      { label: "Name", value: "name", sortBy: "first_name", size: 5 },
      { label: "Email", value: "main_contact_email", size: 8 },
      { label: "External ID", value: "white_label_external_id", size: 5 },
      { label: "Sent", value: "created_at", size: 5 },
      { label: "Status", value: "status", size: 5 },
      { label: "Actions", value: "actions", size: 7, preventSorting: true }
    ];

  headers = merchantSlug ? headers.filter(header => header.value !== "actions") : headers;

  const actions = merchantSlug ? [] : (message === "APPLICATIONS" ?
    [
      { label: "Resend", visible: () => { return true }, value: (data: any) => { return displaySuccessMessage("The invitation was sent", InvitationHandler.resendApplicationInvite(data.id)) } },
      { label: "Remove", visible: () => { return true }, value: (data: any) => { return displaySuccessMessage("The invitation was deleted", InvitationHandler.deleteApplicationInvite(data.id)) } },
    ] : [
      { label: "Resend", visible: () => { return true }, value: (data: any) => { return displaySuccessMessage("The invitation was sent", InvitationHandler.cancelOrResendMerchantInvite(data.id, "RESEND")) } },
      { label: "Cancel", visible: (data: any) => { return data.status !== "CANCELED"; }, value: (data: any) => { return displaySuccessMessage("The invitation was canceled", InvitationHandler.cancelOrResendMerchantInvite(data.id, "CANCEL")) } },
    ]);

  const displaySuccessMessage = async (message: string, promise: Promise<any>): Promise<any> => {
    displayMiniFeedback(message);
    tableRef?.current?.reloadData();
    return Promise.resolve(await promise);
  }

  const getData = async (next: string): Promise<IList> => {
    setLoadingData(true);
    let list: IList = null;
    if (message === "APPLICATIONS") {
      list = await InvitationHandler.getApplicationInvitations(next, merchantSlug, preventPagination);
      list.results = list.results.map(item => {
        return {
          id: item.id,
          email: item.email,
          phone_number: formatAPIPhone(item.phone_number),
          created_at: formatAPIDate(item.created_at),
          status: item.status
        }
      });
    } else {
      list = await InvitationHandler.getMerchantInvites(next, preventPagination);
      list.results = list.results.map(item => {
        return {
          id: item.id,
          name: `${item["main_contact_first_name"] || ""} ${item["main_contact_last_name"] || ""}`,
          main_contact_email: item["main_contact_email"],
          white_label_external_id: item["white_label_external_id"],
          created_at: formatAPIDate(item.created_at),
          status: item.status
        }
      });
    }
    setLoadingData(false);
    return Promise.resolve(list);
  }

  useEffect(() => {
    if (preventPagination) {
      tableRef?.current?.reloadData();
    }
  }, [preventPagination]);

  useEffect(() => {
    if (preventPagination) {
      window.DownloadCSV(tableRows, headers.filter(header => { return header.value !== "actions" }) as Array<any>, "Invitations.csv");
      setPreventPagination(false);
    }
  }, [tableRows]);

  const exportAction = () => {
    setPreventPagination(true);
  }

  return (
    <Popup isInner={true}>
      {message && <>
        <CloseIcon id="invitationStatusPopup" color="dark" />
        <h2>{`${message === "APPLICATIONS" ? 'Applicant' : 'Merchant'} invitation status`}</h2>
        <Grid container>
          <Grid item sm={8}>
            <div className={styles.searchContainer}>
              <SearchBar
                id="invitationStatus_search"
                placeholder={`Search invited ${message === "APPLICATIONS" ? 'applicants' : 'merchants'}`}
                onSearch={setKeyword}
                ref={searchBarRef}
                onClearClick={() => {
                  Analytics.track({ experience: "portal", screen: message === "APPLICATIONS" ? (merchantSlug ? "merchants_applications_invitation_status" : "applicant_invitation_status") : "merchant_invitation_status", object: "clear_search", action: "clicked" } as ITracking);
                }} />
            </div>
          </Grid>
          <Grid item sm={4} style={{ textAlign: "right" }}>
            <ExportButton 
              id="invitationStatusExport"
              exporting={preventPagination}
              disabled={loadingData} 
              onClick={() => {
                exportAction();
                Analytics.track({ experience: "portal", screen: message === "APPLICATIONS" ? (merchantSlug ? "merchants_applications_invitation_status" : "applicant_invitation_status") : "merchant_invitation_status", object: "export_button", action: "clicked" } as ITracking);
              }} />
          </Grid>
        </Grid>
        <Table
          id="invitationStatus_invitationStatusTable"
          data={getData}
          headers={headers}
          onUpdate={(rows: any) => { setTableRows(rows) }}
          action={actions}
          keyword={keyword}
          tableBodyStyle={{ minWidth: 600 }}
          ref={tableRef}
          maxHeight={300}
          onLoadingRows={() => {
            Analytics.track({ experience: "portal", screen: message === "APPLICATIONS" ? (merchantSlug ? "merchants_applications_invitation_status" : "applicant_invitation_status") : "merchant_invitation_status", object: "table_load_more_rows", action: "initiated" } as ITracking);
          }}
          onSort={(field_sorted: string) => {
            Analytics.track({ experience: "portal", screen: message === "APPLICATIONS" ? (merchantSlug ? "merchants_applications_invitation_status" : "applicant_invitation_status") : "merchant_invitation_status", object: "table_sort", action: "successful" } as ITracking, { field_sorted });
          }}
        />
      </>
      }
    </Popup >
  );
};

export default InvitationStatusPopup;

import styles from "./Settings.module.scss";
import { Grid, InfoContainer, LandingPagePreview } from "common";
import React, { useEffect, useState } from "react";
import popUpActions from "reducers/PopUpReducer";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { DOMAIN_PREFIX, POPUPS } from "utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { MerchantHandler } from "actions/MerchantHandler";
import Observer, { EVENTS } from "classes/Observer";
import { getStatus, isForMerchantGlobal } from "utils/helpers";
import { useAuth } from "auth/useAuth";
import { GlobalEdit } from "iconsax-react";
import { useLoadingBar } from "hooks/useLoadingBar";
import useMerchant from "hooks/useMerchant";

interface ILandingPage {
  user_type: "MERCHANT" | "WHITELABEL";
}

const LandingPage: React.FC<ILandingPage> = ({ user_type }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  // merchantSlug is used on viewMerchant
  const { merchantSlug } = params;
  const user = useAuth()?.user;
  const merchant = useMerchant();
  const whitelabel = useAppSelector(state => state.whitelabel);
  const observer = Observer.useObserver(EVENTS.MERCHANT_UPDATED);
  const [ready, setReady] = useState(false);
  const [originalSlug, setOriginalSlug] = useState<string>(null);
  const [isMerchantDeactivated, setIsMerchantDeactivated] = useState(false);
  useLoadingBar(ready);

  useEffect(() => {
    if (merchant) {
      getStatus("Deactivated").then(response => setIsMerchantDeactivated(merchant?.status === response));
    }
  }, [merchant]);

  useEffect(() => {
    if (window.location.pathname.includes("viewMerchant") && originalSlug !== merchant?.slug && merchant?.slug) {
      setOriginalSlug(merchant?.slug);
      navigate("/viewMerchant/" + merchant?.slug + "/accountInformation");
    }
  }, [observer]);

  useEffect(() => {
    if (merchantSlug) {
      MerchantHandler().get(merchantSlug)
        .then(() => {
          setOriginalSlug(merchantSlug);
          setReady(true);
        });
    } else {
      setReady(true);
    }
  }, [params]);

  const isForMerchant = () => {
    return isForMerchantGlobal(user, merchantSlug);
  }

  let rows = null;
  if (isForMerchant()) {
    rows = [
      { label: "URL", value: `${DOMAIN_PREFIX}${merchantSlug || merchant?.slug}` },
      { label: "Logo", value: merchant?.primary_logo || "Not set yet" },
    ]
  } else {
    rows = [
      { label: "URL", value: `${DOMAIN_PREFIX}` },
    ]
  };

  return <div className={styles.contentContainer}>
    {ready && <Grid>
      <Grid horizontal>
        <Grid item>
          <InfoContainer
            id="landing_page_settings_card"
            title="Landing page settings"
            icon={GlobalEdit}
            rows={rows}
            // partner admin has to be able to edit declined merchants
            onClick={isForMerchant() ? () => dispatch(popUpActions.openPopup(POPUPS.EDIT_MERCHANT_LANDING_PAGE_TEMPLATE)) : null}
            disabled={isForMerchant() && isMerchantDeactivated}
          />
        </Grid>
        <Grid item>
          {<InfoContainer
            icon={GlobalEdit}
            id="logo_card"
            style={{ display: "flex", textAlign: "center", justifyContent: "center", backgroundColor: "white" }}>
            {isForMerchant() && merchant?.primary_logo && <img src={merchant?.primary_logo} style={{ width: "auto", margin: "auto", maxHeight: "10rem", maxWidth: "25rem" }} alt={merchant?.name} />}
            {!isForMerchant() && <img src={whitelabel?.secondary_logo} style={{ width: "auto", margin: "auto", maxHeight: "10rem", maxWidth: "25rem" }} alt={whitelabel?.name} />}
            {isForMerchant() && !isMerchantDeactivated && !merchant?.primary_logo && <p className="textMedium">Click the edit icon to upload a logo.</p>}
            {isForMerchant() && isMerchantDeactivated && !merchant?.primary_logo && <p className="textMedium">Logo not set.</p>}
          </InfoContainer>}
        </Grid>
      </Grid>
      <Grid item>
        <LandingPagePreview
          hero={merchant?.industry_template?.hero_image || "/assets/images/merchantLandingBanner.png"}
          subtitle={merchant?.industry_template?.body || null}
          title={merchant?.industry_template?.title || null}
          {...merchant?.color_theme && { colors: [merchant?.color_theme?.primary_light, merchant?.color_theme?.primary_dark, merchant?.color_theme?.secondary] }}
          {...merchant?.primary_logo && { preview_logo: merchant?.primary_logo }}
          forceMerchant={true}
        />
      </Grid>
    </Grid>}
  </div>;
};

export default LandingPage;

import React, { CSSProperties, useEffect, useState } from "react";
import { Plus } from "react-bootstrap-icons";
import styles from "./LogoHeader.module.scss";
import { Button } from "../";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import popUpActions from "reducers/PopUpReducer";
import { POPUPS } from "utils/constants";
import { isSmallScreen } from "utils/helpers";
import { useImageDimensions } from "hooks/useImageDimensions";

interface ILogoHeader {
  help?: boolean;
  merchantLogoPreview?: string;
  merchantInitials?: string;
  /* you need this for landing page settings, when WL logo is uploaded */
  wlLogoPreview?: string;
  type: "MERCHANT" | "WHITELABEL";
  width?: number;
  preview?: boolean;
}

const LogoHeader: React.FC<ILogoHeader> = ({ help, merchantLogoPreview, merchantInitials, wlLogoPreview, type, width, preview }) => {
  const dispatch = useAppDispatch();
  const { whitelabel, merchant } = useAppSelector(state => state);
  const wlLogoDimensions = useImageDimensions(wlLogoPreview || whitelabel?.secondary_logo);
  const [height, setHeight] = useState<number>(0);

  const imageStyle: CSSProperties = {
    width: "auto",
    display: "block",
    objectFit: "contain",
  };

  useEffect(() => {
    if (wlLogoDimensions) {
      setHeight(wlLogoDimensions.height);
    }
  }, [wlLogoDimensions]);

  const getWLLogo = () => {
    if (wlLogoPreview || whitelabel?.secondary_logo) {
      return <img src={wlLogoPreview || whitelabel.secondary_logo} alt="WhiteLabel Logo" style={{ ...imageStyle, height, maxWidth: isOneLogo() ? "100%" : "45%", maxHeight: "45px" }} />;
    }
    return null;
  }

  const getMerchantLogo = () => {
    if (merchantLogoPreview || merchant?.primary_logo) {
      return <img src={merchantLogoPreview || merchant?.primary_logo} alt="Merchant Logo" style={{ ...imageStyle, height, maxWidth: isOneLogo() ? "100%" : "45%", maxHeight: "45px" }} />;
    } else if (merchantInitials) {
      return <p className={styles.initials}>{merchantInitials}</p>
    }
    return null;
  }

  const getLogos = (): any => {
    const wlLogo = getWLLogo();
    const merchantLogo = getMerchantLogo();
    if (type === "WHITELABEL") {
      return wlLogo;
    } else if (type === "MERCHANT" && whitelabel?.merchant_page_layout === "SIDE_BY_SIDE") {
      return <>
        {wlLogo}
        {merchantLogo && <Plus color="var(--darkTextColor)" size={isSmallScreen() ? 20 : 30} />}
        {merchantLogo}
      </>;
    } else if (type === "MERCHANT" && whitelabel?.merchant_page_layout === "POWERED_BY") {
      return merchantLogo;
    }
  }

  const isOneLogo = (): boolean => {
    return type === "WHITELABEL" || type === "MERCHANT" && whitelabel?.merchant_page_layout === "POWERED_BY";
  }

  return (
    <header className={styles.headerInner + (preview ? " " + styles.borderRadius : "")}>
      {getLogos()}
      {help && <Button
        id="logoHeader_helpButton"
        type="button"
        label="Help"
        onClick={() => {
          dispatch(popUpActions.openPopup(POPUPS.HELP));
        }}
      />}
    </header>
  );
};

export default LogoHeader;
import { Container, SVG } from "common";
import React from "react";
import styles from "./DTMLandingPageComingSoon.module.scss";

const DTMLandingPageComingSoon: React.FC = () => {
  return <>
    <Container>
      <div className={styles.svgWrapper}>
        <h1>DTM landing page coming soon</h1>
        <SVG
          src="/assets/images/dtm-landing-page-coming-soon.svg"
          title="Spaceship"
          width={200}
          height={300}
        />
      </div>
    </Container>
  </>;
}

export default DTMLandingPageComingSoon;
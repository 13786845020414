import { useAppSelector } from "reducers/Hooks";
import { POPUPS } from "utils/constants";
import { isPopupOpened } from "utils/helpers";
import HelpPopup from "./HelpPopup";
import InstructionPopup from "./InstructionPopup";
import FeedbackPopup from "./feedback/FeedbackPopup";
import EditSupportPopup from "./EditSupportPopup";
import ConfirmationPopup from "./ConfirmationPopup";

const FullScreenPopups: React.FC = () => {
  const confirmationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.CONFIRMATION_FULL));
  const feedbackPopup = useAppSelector(state => state.popUp.feedbackPopup.display);
  const helpPopup = useAppSelector(state => isPopupOpened(state, POPUPS.HELP));
  const instructionPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.INSTRUCTIONS));
  const editSupportPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_SUPPORT));

  return <>
    {helpPopup && <HelpPopup />}
    {instructionPopup && <InstructionPopup />}
    {feedbackPopup && <FeedbackPopup />}
    {editSupportPopup && <EditSupportPopup />}
    {confirmationPopup && <ConfirmationPopup inner={false} />}
  </>;
};

export default FullScreenPopups;

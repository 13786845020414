import { CloseIcon } from "common";
import { Form, Input, SelectDropdown } from "common/form";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { IPopup } from "common/interfaces";
import Popup from "./Popup";
import { MaxLoanAmountValidation, Schema, SimpleRequireValidation } from "utils/validation/additionalValidation";
import popUpActions from "reducers/PopUpReducer";
import { DEFAULT_MAX_LOAN_AMOUNT } from "utils/constants";
import Observer, { EVENTS } from "classes/Observer";
import { useParams } from "react-router-dom";
import { MerchantHandler } from "actions/MerchantHandler";
import WhiteLabelHandler from "actions/WhiteLabelHandler";
import Analytics, { ITracking } from "classes/Analytics";
import { useAuth } from "auth/useAuth";
import { removeLenderGroup } from "utils/helpers";
import useMerchant from "hooks/useMerchant";

const EditLenderGroupPopup: React.FC<IPopup> = () => {
  const { merchantSlug } = useParams();
  const dispatch = useAppDispatch();
  const merchant = useMerchant();
  const user = useAuth()?.user;

  const [initialValues, setInitialValues] = useState(null);
  const [validationSchema, setValidationSchema] = useState(null);
  const [inputFields, setInputFields] = useState(null);
  const [lenderGroups, setLenderGroups] = useState(null);

  useEffect(() => {
    MerchantHandler().get(merchantSlug)
      .then(response => {
        WhiteLabelHandler.getLenderGroups()
          .then(groups => {
            setLenderGroups(groups.map((item: any) => { return { label: item.name, value: item.id } }));
            let initVals = { max_loan_amount: response?.max_loan_amount || DEFAULT_MAX_LOAN_AMOUNT };
            if (groups.length > 0) {
              initVals["lender_group"] = response?.lender_group?.id || groups.find((item: any) => { return item.default })?.id || "";
            }
            setInitialValues(initVals);
          });
      });
  }, []);

  useEffect(() => {
    if (lenderGroups) {
      let valSch = { max_loan_amount: MaxLoanAmountValidation };
      let inpFields = [];
      if (lenderGroups && lenderGroups?.length > 1 && user?.user_type !== "PARTNER") {
        valSch["lender_group"] = SimpleRequireValidation
        inpFields.push([
          <SelectDropdown selectOptions={lenderGroups} placeholder="Select" name="lender_group" id="editLenderGroupPopup_lender_group" label="New lender group" />,
          <Input name="max_loan_amount" id="editLenderGroupPopup_max_loan_amount" mask="amount" label="Max loan amount" tooltip="Max loan amount must be between $1,000 and $250,000" inputPrefix="$" />
        ]);
      } else {
        inpFields.push([
          <Input name="default_lender_group" readonly id="editLenderGroupPopup_lender_group" label="New lender group" placeholder={lenderGroups.length === 0 ? "Default lender group" : lenderGroups[0].label} />,
          <Input name="max_loan_amount" id="editLenderGroupPopup_max_loan_amount" mask="amount" label="Max loan amount" tooltip="Max loan amount must be between $1,000 and $250,000" inputPrefix="$" />
        ]);
      }
      setValidationSchema(valSch);
      setInputFields(inpFields);
    }
  }, [lenderGroups]);

  const handleClose = () => {
    dispatch(popUpActions.closePopup());
  }

  const handleSubmit = async (data: any) => {
    if (user?.user_type === "PARTNER") {
      data = removeLenderGroup(data);
    }

    MerchantHandler().patch(merchantSlug, data)
      .then(updatedMerchant => {
        if (initialValues?.lender_group && data?.lender_group !== initialValues?.lender_group) {
          Analytics.track({ experience: "portal", screen: "merchants_account_info", object: "lender_group", action: "updated" } as ITracking, { merchant_id: updatedMerchant?.id });
        }
        if (initialValues?.max_loan_amount && data?.max_loan_amount !== initialValues?.max_loan_amount) {
          Analytics.track({ experience: "portal", screen: "merchants_account_info", object: "max_loan_amt", action: "updated" } as ITracking, { merchant_id: updatedMerchant?.id });
        }
        Observer.trigger(EVENTS.MERCHANT_UPDATED);
        handleClose();
        return Promise.resolve();
      });
  }

  return (
    <Popup isInner={true}>
      <>
        <CloseIcon id="editUserPopup" color="dark" />
        {initialValues && validationSchema && inputFields && lenderGroups && <Form
          id="editLenderGroupPopup"
          title={`${merchant?.name} • Edit program settings`}
          submitBtnText="Save"
          onFormSubmit={handleSubmit}
          validationSchema={Schema(validationSchema)}
          values={initialValues}
          buttonPosition={"bottom right"}
          inputFields={inputFields}
          isInsidePopup
        />}
      </>
    </Popup>
  );
};

export default EditLenderGroupPopup;

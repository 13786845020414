import React, { useEffect, useState } from "react";
import styles from "./InformationCard.module.scss";
import { CardTick, SmsEdit, Call, Location, Shop, UserEdit, Global, ProfileCircle, Home, Bucket, Edit, MoneyTick, Tag } from "iconsax-react";
import { Button, CloseIcon, InformationCardLine, LineWithIcon } from "common";
import { useNavigate, useParams } from "react-router-dom";
import SystemHandler from "actions/SystemHandler";
import Analytics, { ITracking } from "classes/Analytics";
import {
  askForConfirmation, concatenateAddress, displayFeedback, displayMiniFeedback,
  getAllStatuses, getMerchantPrograms, getStatus, handleNull, isForMerchantGlobal
} from "utils/helpers";
import { IAddress, IFeedback } from "common/interfaces";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import popUpActions from "reducers/PopUpReducer";
import { DOMAIN_PREFIX, POPUPS } from "utils/constants";
import { formatAmount, formatFullname, formatPhone } from "utils/formatters";
import Observer, { EVENTS } from "classes/Observer";
import WhiteLabelHandler from "actions/WhiteLabelHandler";
import { MerchantHandler } from "actions/MerchantHandler";
import { useAuth } from "auth/useAuth";
import { MerchantTabType } from "common/types";
import useMerchant from "hooks/useMerchant";
import { useFlag } from "hooks/useFlag";
import { useBusinessModel } from "hooks/useBusinessModel";

interface IMerchantInformationCard {
  variant: MerchantTabType;
  merchantSlugProp?: string;
  closeMerchantSummary?: () => void;
  active?: boolean;
}

const MerchantInformationCard: React.ForwardRefRenderFunction<HTMLDivElement, IMerchantInformationCard> = ({ variant, merchantSlugProp, closeMerchantSummary, active }, ref) => {
  const user = useAuth()?.user;
  const whitelabel = useAppSelector(state => state.whitelabel);

  const [annualSalesOptions, setAnnualSalesOptions] = useState([]);
  const [estMonthlyFinanceVolumeOptions, setEstMonthlyFinanceVolumeOptions] = useState([]);
  const [averageTicketOptions, setAverageTicketOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [specialtyOptions, setSpecialtyOptions] = useState([]);
  const [merchantDetailsLabels, setMerchantDetailsLabels] = useState<any>(null);
  const [numberMerchantsOptions, setNumberMerchantsOptions] = useState([]);
  const [partnerTypeOptions, setPartnerTypeOptions] = useState([]);
  const [lenderGroups, setLenderGroups] = useState(null);

  const { merchantSlug } = useParams();
  const merchantUpdated = Observer.useObserver(EVENTS.MERCHANT_UPDATED);
  const [merchantStatuses, setMerchantStatuses] = useState([]);
  const [isMerchantDeactivated, setIsMerchantDeactivated] = useState(false);
  const [isMerchantDeclined, setIsMerchantDeclined] = useState(false);
  const businessModelUpdated = Observer.useObserver(EVENTS.BUSINESS_MODEL_UPDATED);
  const selectedBusinessModel = useBusinessModel();
  const enableDtmDtcToggle = useFlag("dtm-dtc-toggle");

  const getSlug = () => {
    return merchantSlug || merchantSlugProp;
  }

  const merchant = useMerchant(getSlug());

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    MerchantHandler().getStats(getSlug())
  }, [merchantSlug, merchantSlugProp, businessModelUpdated]);

  useEffect(() => {
    if (merchant) {
      getStatus("Deactivated")
        .then(response => {
          setIsMerchantDeactivated(merchant?.status === response);
        });
      getStatus("Declined")
        .then(response => {
          setIsMerchantDeclined(merchant?.status === response);
        });
    }
  }, [merchant]);

  useEffect(() => {
    Promise.all([
      SystemHandler.getAnnualSales(),
      SystemHandler.getMonthlyFinanceVolume(),
      SystemHandler.getAverageTicket(),
      SystemHandler.getIndustry(),
      SystemHandler.getIndustrySpecialty(),
      SystemHandler.getNumberOfMerchants(),
      SystemHandler.getPartnerTypes()
    ]).then(responses => {
      setAnnualSalesOptions(responses[0]);
      setEstMonthlyFinanceVolumeOptions(responses[1]);
      setAverageTicketOptions(responses[2]);
      setIndustryOptions(responses[3]);
      setSpecialtyOptions(responses[4]);
      setNumberMerchantsOptions(responses[5]);
      setPartnerTypeOptions(responses[6]);
    });
  }, []);

  useEffect(() => {
    if (annualSalesOptions?.length > 0 && estMonthlyFinanceVolumeOptions?.length > 0 &&
      averageTicketOptions?.length > 0 && industryOptions?.length > 0 && specialtyOptions?.length > 0) {
      setMerchantDetailsLabels({
        annual_sales: _.find(annualSalesOptions, option => { return option.id === merchant?.annual_sales })?.label || merchant?.annual_sales,
        monthly_financing_volume: _.find(estMonthlyFinanceVolumeOptions, option => { return option.id === merchant?.monthly_financing_volume })?.label || merchant?.monthly_financing_volume,
        average_ticket: _.find(averageTicketOptions, option => { return option.id === merchant?.average_ticket })?.label || merchant?.average_ticket,
        industry: _.find(industryOptions, option => { return option.id === merchant?.industry })?.label || merchant?.industry,
        industry_specialty: _.find(specialtyOptions, option => { return option.id === merchant?.industry_specialty })?.label || handleNull(merchant?.industry_specialty)
      });
    }
  }, [annualSalesOptions, estMonthlyFinanceVolumeOptions, averageTicketOptions,
    industryOptions, specialtyOptions, merchant, numberMerchantsOptions,
    partnerTypeOptions]);

  useEffect(() => {
    // TODO: check if this is used at all, since this is viewMerchant screen, not settings
    if (window.location.pathname.toLowerCase() === "/settings/experiencesettings") {
      navigate('/settings/accountInformation');
    }
    getAllStatuses()
      .then(response => {
        setMerchantStatuses(response);
      });

    WhiteLabelHandler.getLenderGroups()
      .then(response => {
        setLenderGroups(response);
      });
  }, []);

  useEffect(() => {
    MerchantHandler().get(getSlug());
  }, [merchantUpdated]);

  useEffect(() => {
    if (getSlug() || !merchant?.id) {
      MerchantHandler().get(getSlug());
    }
  }, [merchantSlug, merchantSlugProp]);

  const address: IAddress = {
    address1: merchant?.address1,
    address2: merchant?.address2 || "",
    city: merchant?.city,
    state: merchant?.state,
    postal_code: merchant?.postal_code
  };

  const handleHistoryClick = () => {
    dispatch(popUpActions.openPopup(POPUPS.MERCHANT_HISTORY));
  }

  const getStatusId = (status: "Active" | "Pending" | "Deactivated" | "Declined"): number => {
    return _.find(merchantStatuses, item => { return item.name === status })?.id;
  }

  const handleDeactivate = () => {
    MerchantHandler().deactivate(merchantSlug)
      .then(() => {
        MerchantHandler().get(getSlug());
        displayMiniFeedback("Merchant deactivated");
        dispatch(popUpActions.closePopup());
      });
  }

  const handleActivation = () => {
    MerchantHandler().approve(merchantSlug)
      .then(() => {
        MerchantHandler().get(getSlug());
        displayFeedback({
          title: `Success!`,
          body: `You have successfully activated ${merchant?.name}!`,
          type: "MERCHANT"
        } as IFeedback);
      });
  }

  let cardStyle = "";
  let card2Style = "";

  if (variant === "accountInformation") {
    cardStyle = styles.cardMerchantAccountTab;
    card2Style = styles.card2MerchantAccountTab;
  } else if (variant === "usersLocations") {
    cardStyle = styles.cardUsersLocationsTab;
  } else if (variant === "experienceSettings") {
    cardStyle = styles.cardExperienceSettingsTab;
    card2Style = styles.card2ExperienceSettingsTab;
  }

  const isForMerchant = () => {
    return isForMerchantGlobal(user, merchantSlug);
  }

  const editButton = (id, onClick) => {
    return <>
      {onClick && <div className={styles.editInfo}>
        <Edit
          color="var(--primaryTextColor)"
          size={22}
          onClick={onClick}
          id={`${id}_edit_button`}
        />
      </div>}
    </>
  }

  const getMerchantInfo = () => {
    return <>
      {enableDtmDtcToggle && <LineWithIcon icon={CardTick} text={getMerchantPrograms(merchant) || "-"} />}
      <LineWithIcon icon={Location} text={concatenateAddress(address) || "-"} />
      <LineWithIcon icon={Global} text={merchant?.website || "-"} />
      {/* <LineWithIcon icon={InfoCircle} text={merchant?.id} rotate180deg={true} /> */}
      {(user?.user_type === "WHITELABEL") &&
        <LineWithIcon icon={Tag} text={merchant?.external_id || "-"} />
      }
      <LineWithIcon icon={Shop} text={(!merchantDetailsLabels?.industry && !merchantDetailsLabels?.industry_specialty) ? "-" : ((merchantDetailsLabels?.industry || "") + (merchantDetailsLabels?.industry && merchantDetailsLabels?.industry_specialty ? ", " : "") + (merchantDetailsLabels?.industry_specialty || ""))} />
      <LineWithIcon icon={ProfileCircle} text={(merchant?.main_contact_first_name || merchant?.main_contact_last_name) ? (merchant?.main_contact_first_name || "") + " " + (merchant?.main_contact_last_name || "") + " (main contact)" : "-"} />
      <LineWithIcon icon={Call} text={formatPhone(merchant?.main_contact_phone) || "-"} />
      <LineWithIcon icon={SmsEdit} text={merchant?.main_contact_email || "-"} />
    </>
  };

  const handleMoreInfoClick = () => {
    if (getSlug()) {
      Analytics.track({ experience: "portal", screen: "merchants", object: "more_information_button", action: "clicked" } as ITracking, { merchant_id: merchant?.id });
      navigate(`/viewMerchant/${getSlug()}/accountInformation`);
    }
  }

  return <>
    {variant === "merchants" && <>
      <div id="merchantSummaryWrapper" className={active ? "merchantSummaryActive" : ""} ref={ref}>
        <div className={styles.cardsWrapper2}>
          <div className={styles.cardsWrapper + " " + styles.merchantInformationCard}>
            <div className={styles.card + " " + cardStyle}>
              <CloseIcon id="applicationSummary" color="dark" onClose={closeMerchantSummary} />
              <h2 style={{ color: "var(--primaryVariationTextColor)" }}>{merchant?.name}</h2>
              {getMerchantInfo()}
            </div>
            <div className={styles.card2 + " " + card2Style}>
              <h2 style={{ color: "var(--primaryVariationTextColor)" }}>Their Applications</h2>
              <LineWithIcon icon={MoneyTick} text={"Approval rate • "} textPrimary={formatAmount(merchant?.approval_rate * 100 || 0) + "%"} />
              <LineWithIcon icon={CardTick} text={"Conversion rate • "} textPrimary={formatAmount(merchant?.conversion_rate * 100 || 0) + "%"} />
              <div className={styles.moreInfoWrapper}>
                <Button
                  id="btn_moreInformation"
                  label="More information"
                  onClick={handleMoreInfoClick}
                  className={styles.moreInfoButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>}

    {variant === "accountInformation" && <>
      <div id="merchantAccountTabWrapper" className={styles.cardsWrapper2}>
        <div className={styles.cardsWrapper + " " + styles.merchantInformationCard}>
          <div className={styles.card + " " + cardStyle + (isMerchantDeactivated ? " " + styles.disabled : "")}>
            {editButton(variant + "_editBusinessAndMainContact",
              () => dispatch(popUpActions.openPopup(POPUPS.EDIT_MERCHANT_BUSINESS_AND_MAIN_CONTACT))
            )}
            <h2 style={{ color: "var(--primaryVariationTextColor)" }}>{merchant?.name + (isMerchantDeactivated ? " (deactivated)" : isMerchantDeclined ? " (declined)" : "")}</h2>
            {getMerchantInfo()}

            <div className={styles.buttonsWrapper}>
              <div className={styles.buttons}>
                <Button
                  id="btn_history"
                  label="History"
                  style={{ width: 160, height: 48, fontSize: 14, fontWeight: 600 }}
                  onClick={handleHistoryClick}
                />

                {merchant && merchant?.status !== getStatusId("Pending") && user?.user_type === "WHITELABEL" && <>
                  <Button
                    id="merchant_information_card_deactivate_merchant"
                    type="button"
                    label={merchant?.status === getStatusId("Deactivated") || merchant?.status === getStatusId("Declined") ? "Activate" : "Deactivate"}
                    onClick={() => {
                      if (merchant?.status === getStatusId("Deactivated") || merchant?.status === getStatusId("Declined")) {
                        askForConfirmation(`Are you sure you want to activate ${merchant?.name}?`,
                          { text: 'Yes, I do', action: () => { dispatch(popUpActions.closePopup()); handleActivation() } },
                          { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });
                      } else {
                        askForConfirmation(`Are you sure you want to deactivate ${merchant?.name}?`,
                          { text: 'Yes, deactivate', action: () => { handleDeactivate() } },
                          { text: 'No, thanks', action: () => { dispatch(popUpActions.closePopup()) } });
                      }
                    }}
                    variant="secondary"
                    style={{ width: 160, height: 48, fontSize: 14, fontWeight: 600 }}
                  />
                </>}
              </div>
            </div>
          </div>
          <div className={styles.card2 + " " + card2Style}>
            {editButton(variant + "_editEstimatedSales",
              () => dispatch(popUpActions.openPopup(POPUPS.EDIT_ESTIMATED_SALES))
            )}
            <h2 style={{ color: "var(--primaryVariationTextColor)" }}>Estimated sales</h2>

            <InformationCardLine textLabel="Average ticket" textValue={merchantDetailsLabels?.average_ticket} />
            <InformationCardLine textLabel="Est. monthly finance volume" textValue={merchantDetailsLabels?.monthly_financing_volume} />
            <InformationCardLine textLabel="Annual sales" textValue={merchantDetailsLabels?.annual_sales} />

          </div>
        </div>
      </div>
    </>
    }

    {
      variant === "usersLocations" && <>
        <div id="merchantUsersLocationsTabWrapper" className={styles.cardsWrapper2}>
          <div className={styles.cardsWrapper + " " + styles.merchantInformationCard}>
            <div className={styles.card + " " + cardStyle}>
              <h2 style={{ color: "var(--primaryVariationTextColor)" }}>{merchant?.name} Associations</h2>

              <LineWithIcon icon={Home} text={"Created by " + formatFullname(merchant?.created_by?.first_name, merchant?.created_by?.last_name)} />
              <LineWithIcon icon={UserEdit} text={"Creator " + merchant?.created_by?.account_name} />

            </div>
          </div>
        </div>
      </>
    }

    {
      variant === "experienceSettings" && <>
        <div id="merchantExperienceSettingsTabWrapper" className={styles.cardsWrapper2}>
          <div className={styles.cardsWrapper + " " + styles.merchantInformationCard}>
            <div className={styles.card + " " + cardStyle}>
              {editButton(variant + "_logoSettings",
                () => dispatch(popUpActions.openPopup(POPUPS.EDIT_MERCHANT_LANDING_PAGE_TEMPLATE))
              )}


              <h2 style={{ color: "var(--primaryVariationTextColor)" }}>{merchant?.name} Logo</h2>
              {isForMerchant() && merchant?.primary_logo && <img className={styles.logo} src={merchant?.primary_logo} alt={merchant?.name} />}
            </div>

            {(!enableDtmDtcToggle || selectedBusinessModel !== "DTM") && <div className={styles.card2 + " " + card2Style}>
              {editButton(variant + "_editLenderGroup",
                () => dispatch(popUpActions.openPopup(POPUPS.EDIT_LENDER_GROUP))
              )}
              <h2 style={{ color: "var(--primaryVariationTextColor)" }}>Applications Settings</h2>
              <InformationCardLine textLabel="Lender group" textValue={merchant?.lender_group?.name || lenderGroups?.find(item => { return item.default })?.name || "Default lender group"} />
              <InformationCardLine textLabel="Max loan amount" textValue={"$" + merchant?.max_loan_amount} />
            </div>}

            <div className={styles.card3}>

              {editButton(variant + "_urlSettings",
                () => dispatch(popUpActions.openPopup(POPUPS.EDIT_MERCHANT_LANDING_PAGE_TEMPLATE))
              )}
              <h2 style={{ color: "var(--primaryVariationTextColor)" }}>Experience Settings</h2>
              <LineWithIcon icon={Bucket} text={"#" + whitelabel.primary_color + " (primary)"} />
              <LineWithIcon icon={Bucket} color="var(--secondaryColor)" text={"#" + whitelabel.secondary_color + " (secondary)"} />
              <LineWithIcon icon={Global} text={DOMAIN_PREFIX} textPrimary={getSlug()} />
            </div>
          </div>
        </div>
      </>
    }

  </>;
}

export default React.forwardRef(MerchantInformationCard);


import styles from "./Confirmation.module.scss";
import { Button, CloseIcon, Padding, SVG } from "common";
import React from "react";
import Popup from "./Popup";
import { useAppSelector } from "reducers/Hooks";
import { IConfirmationButton } from "common/interfaces";

interface IConfirmationPopup {
  inner: boolean;
}

const ConfirmationPopup: React.FC<IConfirmationPopup> = ({ inner }) => {
  const message = useAppSelector(state => state.popUp.messagePassing);
  const noButton: IConfirmationButton = message?.noButton;
  const yesButton: IConfirmationButton = message?.yesButton;
  const imageSrc: string = message?.imageSrc;
  const title: string = message?.title;

  return <Popup isInner={inner} maxWidth={message?.maxWidth ? message?.maxWidth : "fit-content"}>
    <>
      <Padding top="50px" bottom="10px" left="0px" right="0px">
        <CloseIcon id="confirmationPopup" color="dark" />
        {title && <>
          <h1 className={`${styles.question}`}>
            {message?.title}
          </h1>
          <div className={styles.subtitle}>
            {message?.body}
          </div>
        </>
        }
        {!title && <div className={`${styles.question} big`}>
          {/* body is the title */}
          {message?.body}
        </div>
        }
        {imageSrc && <div className={styles.svgWrapper}>
          <SVG
            src={imageSrc}
            title={`Empty`}
            height={150}
          />
        </div>
        }
        <div className={styles.btnContainer}>
          <Button id="confirmationPopup_btnNo" label={noButton?.text} variant="secondary" onClick={noButton?.action} />
          <Button id="confirmationPopup_btnYes" label={yesButton?.text} onClick={yesButton?.action} />
        </div>
      </Padding>
    </>
  </Popup>;
};

export default ConfirmationPopup;

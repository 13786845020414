import { ISettingsView } from "common/interfaces";
import { IPanel } from "common/parts/panel/Panel";
import { ITabContent } from "common/parts/tabs/TabContent";
import { Bucket, Global } from "iconsax-react";
import { CSSProperties, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import LandingPageContent from "./content/LandingPageContent";
import useMerchant from "hooks/useMerchant";
import { formatAPICurrency } from "utils/formatters";
import { DEFAULT_MAX_LOAN_AMOUNT, POPUPS } from "utils/constants";
import { removeProtocol } from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";
import DTMLandingPageComingSoon from "./content/DTMLandingPageComingSoon";
import { useBusinessModel } from "hooks/useBusinessModel";
import { useFlag } from "hooks/useFlag";

const useExperienceSettings = (): ISettingsView => {
  const dispatch = useAppDispatch();
  const whitelabel = useAppSelector(state => state.whitelabel);
  const merchant = useMerchant();
  const [panels, setPanels] = useState<Array<IPanel>>([]);
  const selectedBusinessModel = useBusinessModel();
  const enableDtmDtcToggle = useFlag("dtm-dtc-toggle");
  const logoStyle: CSSProperties = {
    width: "auto",
    height: "auto",
    maxWidth: "90%",
    maxHeight: "100px",
    margin: "auto",
    display: "block",
    objectFit: "contain"
  };

  useEffect(() => {
    if (whitelabel?.id && merchant?.id) {
      const panels = [
        {
          title: "Landing Page Logo", customContent: <img src={merchant?.primary_logo} alt={`${merchant?.name} secondary logo`} style={logoStyle} />,
          editAction: () => { dispatch(popUpActions.openPopup(POPUPS.EDIT_MERCHANT_LANDING_PAGE_TEMPLATE)) }
        },
        {
          title: "Application Settings", lines: [
            { label: `Lender group ${merchant?.lender_group && merchant.lender_group?.name || "Default lender group"}` },
            { label: `Max loan amount ${formatAPICurrency(merchant?.max_loan_amount || DEFAULT_MAX_LOAN_AMOUNT)}` }
          ]
        },
        {
          title: "Experience Settings", lines: [
            { icon: Bucket, label: `#${whitelabel.primary_color} (primary)` },
            { icon: Bucket, label: `#${whitelabel.secondary_color} (secondary)`, iconColor: "secondaryColor" },
            { icon: Global, label: `${merchant?.domain}` }
          ],
          editAction: () => { dispatch(popUpActions.openPopup(POPUPS.EDIT_MERCHANT_LANDING_PAGE_TEMPLATE)) }
        },
      ];

      if (enableDtmDtcToggle && selectedBusinessModel === "DTM") {
        panels.splice(1, 1);
      }

      setPanels(panels);
    }
  }, [whitelabel, merchant, selectedBusinessModel]);

  let tabContent: ITabContent = {
    sections: [
      { title: "Landing Page Preview", body: <LandingPageContent /> },
    ]
  }
  if (enableDtmDtcToggle && selectedBusinessModel === "DTM") {
    tabContent = {
      sections: [
        { title: "Landing Page Preview", body: <DTMLandingPageComingSoon /> },
      ]
    }
  }

  return { panels, tabContent };
}

export default useExperienceSettings;
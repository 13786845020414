import { IPanel } from "common/parts/panel/Panel";
import { IPanelLine } from "common/parts/panel/PanelLine";
import { ITabContent } from "common/parts/tabs/TabContent";
import { Call, ProfileCircle, Sms, Location, Global, Shop, Home, UserEdit, Tag, CardTick } from "iconsax-react";
import Analytics, { ITracking } from "classes/Analytics";
import { useAppDispatch } from "reducers/Hooks";
import popUpActions from "reducers/PopUpReducer";
import { POPUPS, UW_APP_STATUSES } from "utils/constants";
import { useEffect, useState } from "react";
import { concatenateAddress, getMerchantPrograms, removeProtocol } from "utils/helpers";
import { IAddress, ISettingsView } from "common/interfaces";
import { formatAPIPhone } from "utils/formatters";
import SystemHandler from "actions/SystemHandler";
import LocationsTable from "./content/LocationsTable";
import UsersTable from "../shared/UsersTable";
import useMerchant from "hooks/useMerchant";
import { useFlag } from "hooks/useFlag";
import useUnderwritingApplications from "hooks/useUnderwritingApplications";
import DTMAppStatusEnum from "common/enums/DTMAppStatusEnum";

const useBusinessAccount = (): ISettingsView => {
  const dispatch = useAppDispatch();
  const merchant = useMerchant();
  const [addressContact, setAddressContact] = useState<Array<IPanelLine>>(null);
  const [associations, setAssociations] = useState<Array<IPanelLine>>(null);
  const [industryOptions, setIndustryOptions] = useState(null);
  const [specialtyOptions, setSpecialtyOptions] = useState(null);
  const [panels, setPanels] = useState<Array<IPanel>>([]);
  const dtmFlowEnabled = useFlag("underwriting-dtm-flow");
  const [dtmAppStatus, verified, declined, buttonLabel] = useUnderwritingApplications();
  const enableDtmDtcToggle = useFlag("dtm-dtc-toggle");

  const getIndustryAndSpecialty = () => {
    const industryLabel = merchant?.industry ? industryOptions.find((industry: any) => industry.id === merchant.industry)?.label : '';
    const specialtyLabel = merchant?.industry_specialty ? specialtyOptions.find((specialty: any) => specialty.id === merchant.industry_specialty)?.label : '';

    if (!industryLabel && !specialtyLabel) {
      return "-";
    }
    return [industryLabel, specialtyLabel].filter(Boolean).join(", ");
  };

  useEffect(() => {
    if (merchant?.id && industryOptions && specialtyOptions) {

      const contactAddress = [{ icon: Location, label: concatenateAddress({ ...merchant } as IAddress) },
      { icon: Global, label: merchant?.website },
      { icon: Shop, label: getIndustryAndSpecialty() },
      { icon: ProfileCircle, label: merchant.main_contact_first_name ? `${merchant.main_contact_first_name} ${merchant.main_contact_last_name} (main contact)` : "-" },
      { icon: Call, label: formatAPIPhone(merchant.main_contact_phone) },
      { icon: Sms, label: merchant.main_contact_email || "-" },
      ];
      if (enableDtmDtcToggle) {
        contactAddress.unshift({ icon: CardTick, label: getMerchantPrograms(merchant) });
      }
      setAddressContact(contactAddress);
      setAssociations([
        { icon: Home, label: `Created by ${merchant?.partner?.name || merchant?.whitelabel?.name}` },
        { icon: UserEdit, label: `Creator ${merchant?.created_by?.first_name} ${merchant?.created_by?.last_name}` },
      ]);
    }
  }, [merchant, industryOptions, specialtyOptions]);

  useEffect(() => {
    if (addressContact && associations) {
      setPanels([
        { title: merchant?.name, lines: addressContact, editAction: () => { dispatch(popUpActions.openPopup(POPUPS.EDIT_MERCHANT_BUSINESS_AND_MAIN_CONTACT)) } },
        {
          title: "Associations",
          lines: associations,
          actions: verified
                && !declined
                && dtmFlowEnabled
                && ![DTMAppStatusEnum.NOT_ELIGIBLE,  DTMAppStatusEnum.UNDER_REVIEW].includes(dtmAppStatus)
              ? [{
                  label: buttonLabel,
                  id: "dtm-program-btn",
                  color: "primary",
                  visible: true,
                  onClick: () => { dispatch(popUpActions.openPopup(POPUPS.DTM_PROCESS)) }
                }]
              : null,
          bottomBannerText: dtmFlowEnabled && dtmAppStatus === DTMAppStatusEnum.UNDER_REVIEW
              ? "DTM application in review"
              : null
        },
      ]);
    }
  }, [addressContact, associations, dtmAppStatus]);

  useEffect(() => {
    Promise.all([
      SystemHandler.getIndustry(),
      SystemHandler.getIndustrySpecialty()
    ]).then(responses => {
      setIndustryOptions(responses[0]);
      setSpecialtyOptions(responses[1]);
    });
  }, []);

  const tabContent: ITabContent = {
    sections: [
      {
        title: "Users",
        body: <UsersTable />,
        actions: [
          {
            label: "Invite user", visible: true, color: "primary", id: "invite-user-btn", onClick: () => {
              Analytics.track({ experience: "portal", screen: "settings", object: "invite_user_button", action: "clicked" } as ITracking);
              dispatch(popUpActions.openPopup(POPUPS.INVITE_USER));
            }
          }
        ]
      },
      {
        title: "Locations",
        body: <LocationsTable />,
        actions: [
          {
            label: "Add location", visible: true, color: "primary", id: "add-location-btn", onClick: () => {
              Analytics.track({ experience: "portal", screen: "settings_locations", object: "add_location_button", action: "clicked" } as ITracking, { merchant_id: merchant?.id });
              dispatch(popUpActions.openPopup(POPUPS.ADD_NEW_LOCATION));
            }
          }
        ]
      }
    ]
  }
  return { panels, tabContent };
}

export default useBusinessAccount;
import React, { useEffect, useState } from "react";
import styles from "./InformationCard.module.scss";
import { CardTick, ReceiptEdit, Mouse, UsdCoin, SmsEdit, Call, Location, DollarCircle, MessageNotif, Shop, UserEdit } from "iconsax-react";
import { Button, LineWithIcon } from "common";
import LoanApplicationHandler from "actions/LoanApplicationHandler";
import { useParams } from "react-router-dom";
import SystemHandler from "actions/SystemHandler";
import moment from "moment";
import Analytics, { ITracking } from "classes/Analytics";
import { concatenateAddress, displayMiniFeedback } from "utils/helpers";
import { IAddress } from "common/interfaces";
import _ from "lodash";
import { useAppDispatch } from "reducers/Hooks";
import popUpActions from "reducers/PopUpReducer";
import { POPUPS } from "utils/constants";
import { formatFullname, formatPhone } from "utils/formatters";

const ApplicationInformationCard: React.FC = () => {
  const [details, setDetails] = useState(null);
  const [loanPurposeOptions, setLoanPurposeOptions] = useState([]);
  const { applicantId } = useParams();

  const [countdown, setCountdown] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [lastSent, setLastSent] = useState(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (applicantId) {
      SystemHandler.getLoanPurposeOptions()
        .then(options => {
          setLoanPurposeOptions(options);
          getApplication();
        });
    }
  }, [applicantId]);

  const getApplication = () => {
    LoanApplicationHandler.get(applicantId)
      .then(response => {
        setDetails(response);
        if (response?.offers_email_last_sent_at) {
          const localtime = moment(response?.offers_email_last_sent_at);
          setLastSent(localtime.format('MM/DD/YY h:mm a z'));
          setEndTime(localtime.add(5, 'minutes'));
        }
      });
  }

  useEffect(() => {
    if (endTime) {
      const id = setInterval(() => {
        const currentTime = moment();
        const diff = moment.duration(endTime.diff(currentTime));

        const minutes = diff.minutes();
        const seconds = diff.seconds();

        setCountdown(`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);

        if (minutes <= 0 && seconds <= 0) {
          clearInterval(id);
          setCountdown(null);
          setEndTime(null);
        }
      }, 1000);

      setIntervalId(id);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [endTime]);

  const resendOffersEmail = () => {
    if (!endTime || moment().isAfter(endTime)) {
      LoanApplicationHandler.resendOffersEmail(applicantId)
        .then(() => {
          Analytics.track({ experience: "portal", screen: "view_application_account_info", object: "offer_email_resend", action: "successful" } as ITracking, { merchant_id: details?.merchant?.id });
          displayMiniFeedback('Email re-sent successfully');
          setEndTime(moment().add(5, 'minutes'));
          setLastSent(moment().format('MM/DD/YY h:mm a z'));
        });
    }
  }

  const address: IAddress = {
    address1: details?.address1,
    city: details?.city,
    state: details?.state,
    postal_code: details?.postal_code,
  };

  const handleHistoryClick = () => {
    dispatch(popUpActions.openPopup(POPUPS.APPLICATION_HISTORY));
  }

  const displayResendOfferEmail = details?.offered_at && ["Offered", "Clicked"].includes(details?.status);

  return <>
    <div className={styles.cardsWrapper2}>
      <div className={styles.cardsWrapper}>
        <div className={styles.card}>
          <h2 style={{ color: "var(--primaryVariationTextColor)" }}>{formatFullname(details?.first_name, details?.last_name)}</h2>

          <LineWithIcon icon={SmsEdit} text={details?.email} />
          <LineWithIcon icon={Call} text={formatPhone(details?.phone_number)} />
          <LineWithIcon icon={Location} text={concatenateAddress(address)} />
          <LineWithIcon icon={DollarCircle} text={_.find(loanPurposeOptions, option => { return option.id === details?.loan_purpose })?.label || details?.loan_purpose} />
          <LineWithIcon icon={MessageNotif} text={details?.source} />

          <div className={styles.buttonsWrapper}>
            <div className={styles.buttons} style={{ left: displayResendOfferEmail ? 45 : 145 }}>
              <Button
                id="btn_history"
                label="History"
                style={{ width: 160, height: 48, fontSize: 14, fontWeight: 600 }}
                onClick={handleHistoryClick}
              />

              {displayResendOfferEmail &&
                <div className={styles.resendOffers}>
                  <Button
                    id="resend_offers_emails"
                    label="Resend offers"
                    style={{ width: 160, height: 48, fontSize: 14, fontWeight: 600 }}
                    disabled={endTime && moment().isBefore(endTime)}
                    onClick={resendOffersEmail}
                  />
                  <p style={{
                    textAlign: "right",
                    marginTop: 10,
                    marginBottom: -20,
                    fontSize: 11
                  }}>
                    {countdown && <>{`Please wait ${countdown} to retry`}<br /></>}
                    {lastSent && <>{`Last email: ${lastSent}`}</>}
                  </p>
                </div>
              }
            </div>
          </div>
          {/* </>} */}
        </div>

        <div className={styles.card2}>
          <h2 style={{ color: "var(--primaryVariationTextColor)" }}>Applicant Associations</h2>
          <LineWithIcon icon={Shop} text={details?.merchant?.name} />
          <LineWithIcon icon={UserEdit} text={formatFullname(details?.created_by?.first_name, details?.created_by?.last_name)} />
          <LineWithIcon icon={Location} text={details?.location?.name} />
        </div>
      </div>
    </div>
  </>;
}

export default ApplicationInformationCard;
import React, { useEffect } from "react";
import { HeaderWL, LogoHeader, Button, Container as InternalContainer, HelpButton, LocationSelector, ReCaptchaDisclaimer } from "common";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./LocationBorrower.module.scss";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { useNavigate } from "react-router-dom";
import LocalStorage from "classes/LocalStorage";
import { Hidden } from "@mui/material";
import useMerchant from "hooks/useMerchant";
import Analytics, { ITracking } from "classes/Analytics";
import appActions from "reducers/AppReducer";

const LocationBorrower: React.FC = () => {
  const dispatch = useAppDispatch();
  const merchant = useMerchant();
  const navigate = useNavigate();
  let selectedLocation = LocalStorage.get<any>("selected_location");
  const selectedLocationRedux = useAppSelector(state => state.app.selectedLocation);

  if (selectedLocationRedux && !selectedLocation) {
    LocalStorage.save("selected_location", selectedLocationRedux);
    selectedLocation = selectedLocationRedux;
  }

  const moveNext = () => {
    if (selectedLocation) {
      LocalStorage.remove("application_locked");
      LocalStorage.remove("equal_housing");
      LocalStorage.remove("no_offers");
      navigate(`../${merchant?.slug}/borrowerExperience/LoanInformation`);
    }
  }

  useEffect(() => {
    // on mobile, user goes to the next screen (Loan Information) without confirming location with clicking the next button, window.innerWidth < 900 means mobile
    if (selectedLocation && window.innerWidth < 900) {
      moveNext();
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (!selectedLocation && !selectedLocationRedux && merchant?.locations?.length === 1) {
      const loc = merchant?.locations[0];
      dispatch(appActions.selectLocation(loc));
      LocalStorage.save("selected_location", loc);
      navigate(`/${merchant?.slug}/borrowerExperience/LoanInformation`);
    }
  }, [selectedLocation, selectedLocationRedux, merchant]);

  useEffect(() => {
    if (merchant && merchant?.locations.length == 0) {
      Analytics.track({ experience: "borrower", screen: "application_select_location", object: "form_submission", action: "impossible" } as ITracking, null);
    }
  }, [merchant]);

  return (
    <>
      <div className={styles.desktopHeader}>
        <HeaderWL />
      </div>
      <div className={styles.mobileHeader}>
        <LogoHeader type="MERCHANT" help />
      </div>
      <main className={styles.locationBorrowerContainer}>
        <Container fluid className={styles.helpContainer}>
          <Row>
            <Col className={styles.helpCol1}>
              <HelpButton type="BORROWER" />
            </Col>
          </Row>
        </Container>
        <div className={styles.invitationFormContainer}>
          <InternalContainer className={styles.invitationFormInner}>
            <div className={styles.brandLogo}>
              {merchant?.primary_logo && <img src={merchant?.primary_logo} alt="merchant logo" />}
            </div>
            <h2>Select your location:</h2>
            <LocationSelector
              id="locationBorrower_selector"
              onSelection={(loc) => {
                dispatch(appActions.selectLocation(loc));
                // if user refreshes this page, you don't have the application_id yet
                LocalStorage.save("selected_location", loc);
                Analytics.track({ experience: "borrower", screen: "application_select_location", object: "location", action: "selected" } as ITracking, null);
              }}
              selectedLocationId={selectedLocation?.id || selectedLocationRedux?.id}
              locations={merchant?.locations}
            />
            <Hidden mdDown>
              <div className={styles.buttonContainer}>
                <Button
                  id="locationBorrower_next"
                  type="button"
                  label="Next"
                  disabled={!selectedLocation && !selectedLocationRedux}
                  onClick={moveNext} />
              </div>
            </Hidden>
          </InternalContainer>
        </div>
        <ReCaptchaDisclaimer type="INVITATION_FLOWS" />
      </main>
    </>
  );
};

export default LocationBorrower;

import React, { ReactElement, useEffect, useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./BorrowerExperience.module.scss";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  AddressInformation, Agreement, ContactInformation, DTCFinancialInformation,
  DTMFinancialInformation,
  LoanInformation, PersonalInformation, SocialSecurity
} from "./forms";
import {
  ToolTip, Stepper, HeaderWL, WhiteFooter, HelpButton, ResizeDetection,
  ReCaptchaDisclaimer
} from "common";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { getFlowStep, isSmallScreen } from "utils/helpers";
import { IStepper } from "common/interfaces";
import { useNavigate, useLocation } from "react-router-dom";
import LocalStorage from "classes/LocalStorage";
import useMerchant from "hooks/useMerchant";
import strings from "localization/Strings";
import Analytics, { ITracking } from "classes/Analytics";
import appActions from "reducers/AppReducer";
import Confirmation from "./forms/Confirmation";
import DownPayment from "./forms/DownPayment";
import borrowerActions from "reducers/BorrowerReducer";
import { BusinessModelType } from "common/types";

const Borrower: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isWidget = useAppSelector(state => state.app.isWidget);
  const whitelabel = useAppSelector(state => state.whitelabel);
  const merchant = useMerchant();

  useEffect(() => {
    if (isWidget) {
      const style = document.documentElement.style;
      style.setProperty('--bgColor', '#FFFFFF');
    }
  }, [isWidget]);

  useEffect(() => {
    dispatch(borrowerActions.setLenderType(merchant?.programs.includes("DTM") ? "DTM" : "DTC"));
  }, [merchant]);

  const DTC_STEPS: Array<ReactElement<IStepper>> = useMemo(() => [
    <LoanInformation path="LoanInformation" screen="application_loan_info" />,
    <PersonalInformation path="PersonalInformation" screen="application_personal_info" />,
    <ContactInformation path="ContactInformation" screen="application_contact_info" />,
    <AddressInformation path="AddressInformation" screen="application_address_info" />,
    <DTCFinancialInformation path="FinancialInformation" screen="application_financial_info" />,
    <SocialSecurity path="SocialSecurity" screen="application_ssn" />,
    <Confirmation path="Confirmation" screen="application_confirmation" />,
    <Agreement path="Agreement" screen="application_agreements" />
  ], []);
  const DTM_STEPS: Array<ReactElement<IStepper>> = useMemo(() => [
    <LoanInformation path="LoanInformation" screen="application_loan_info" />,
    <PersonalInformation path="PersonalInformation" screen="application_personal_info" />,
    <ContactInformation path="ContactInformation" screen="application_contact_info" />,
    <AddressInformation path="AddressInformation" screen="application_address_info" />,
    <DTMFinancialInformation path="FinancialInformation" screen="application_financial_info" />,
    <SocialSecurity path="SocialSecurity" screen="application_ssn" />,
    <Confirmation path="Confirmation" screen="application_confirmation" />,
    <Agreement path="Agreement" screen="application_agreements" />
  ], []);
  const STEPS = merchant?.programs.includes("DTM") ? DTM_STEPS : DTC_STEPS;
  const totalStepsCount = useMemo(() => {
    return STEPS.length;
  }, [STEPS]);

  const [step, setStep] = React.useState<number>(0);
  const [progressStatus, setProgressStatus] = React.useState<number>(100 / totalStepsCount);

  const applicationLocked: boolean = LocalStorage.get<boolean>("application_locked");
  const locationId: string = LocalStorage.get<any>("selected_location")?.id;
  let vuid = LocalStorage.get<string>("vuid");
  let applicationId = LocalStorage.get<number>("application_id");

  const stepHandler = (moveNext: Boolean) => {
    let newStep = moveNext ? Math.min(step + 1, totalStepsCount - 1) : Math.max(step - 1, 0);
    if (!moveNext) {
      let screen = newStep < STEPS.length ? STEPS[newStep].props.screen : "";
      if (screen) {
        Analytics.track({ experience: "borrower", screen: screen, object: "back_arrow", action: "clicked" } as ITracking, null);
      }
    }
    setStep(newStep);
    goTo(STEPS[newStep].props.path);
  };

  useEffect(() => {
    setStep(getFlowStep(STEPS, 3));
    if (step === 0) {
      goTo(STEPS[step].props.path);
    }
  }, [merchant?.slug]);

  useEffect(() => {
    setStep(getFlowStep(STEPS, 3));
  }, [location]);

  useEffect(() => {
    setProgressStatus((step + 1) * 100 / totalStepsCount);
  }, [step]);

  const goTo = (path: string) => {
    // so the data is not null when calling onNext in LoanInformation, since LoanInformation sets vuid and 
    // applicationId - without this, LoanInformation next button would go back to locationBorrower
    if (path == "PersonalInformation") {
      vuid = LocalStorage.get<string>("vuid");
      applicationId = LocalStorage.get<number>("application_id");
    }

    // prevent going to specific borrower screen by typing url - show loan information screen only if location is selected, 
    // show other screens only if application is in progress
    if (((path === "LoanInformation" || isWidget) && locationId) || (applicationId && vuid)) {
      if (merchant?.slug) {
        const pathname = window.location.pathname;
        const newPath = `/${merchant?.slug}/borrowerExperience/${path}`;

        if (newPath != pathname) {
          navigate(newPath);
          if (path === "LoanInformation" && applicationLocked) {
            navigate("/Offers");
          } else {
            dispatch(appActions.addVisitedStep(path));
          }
        }
      }
    } else {
      navigate(`/${merchant?.slug || window.localStorage.getItem('merchant-slug')}/location`);
    }
  }

  const track = (object: string, action: "hover" | "clicked") => {
    Analytics.track({ experience: "borrower", screen: STEPS[step].props.screen, object, action } as ITracking, null);
  }

  return (
    <ResizeDetection onOrientationChange={() => {
      document.body.click();
    }}>
      <HeaderWL instructions="Fill out the information and we'll match you with offers from our network of lenders!" />
      <main className={styles.borrowerExperienceContainer}>
        <Container fluid className={styles.helpContainer}>
          <Row>
            <Col className={styles.helpCol1}>
              <HelpButton type="BORROWER" onClick={() => track("help_link", "clicked")} />
            </Col>
            <Col className={styles.helpCol2}>
              <ToolTip
                textToolTip="Advertiser Disclosure"
                text={strings.advertiserDisclosure.replaceAll("{whitelabelName}", whitelabel?.name)}
                placement="bottom"
                onHover={() => { track("advertiser_discl_link", "hover") }}
              />
            </Col>
          </Row>
        </Container>
        <div className={styles.invitationFormContainer} style={{ paddingTop: isSmallScreen() ? "3rem" : 0 }}>
          <Stepper steps={STEPS} step={step} onNext={() => stepHandler(true)} onBack={() => stepHandler(false)} />
        </div>
        {step < totalStepsCount && (
          <ProgressBar
            now={progressStatus}
            className={styles.progressBar}
            style={{
              height: "3rem",
              borderRadius: "0",
            }}
          />
        )}
      </main>
      <WhiteFooter
        onPartnerGuaranteeDisclosureHover={() => track("partner_guarantee_discl_link", "hover")}
        onPrivacyPolicyClick={() => track("privacy_policy_link", "clicked")}
        onRepresentativeExampleHover={() => track("rep_example_discl_link", "hover")}
        onTermsOfServiceClick={() => track("terms_service_link", "clicked")} />
      <ReCaptchaDisclaimer type="INVITATION_FLOWS" />
    </ResizeDetection>
  );
};

export default Borrower;
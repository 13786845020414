import React, { CSSProperties, useEffect, useRef, useState } from "react";
import ShowSensitiveInfo from "./ShowSensitiveInfo";
import moment from 'moment';
import { stripOutNonDigits } from "utils/formatters";

interface IInput {
  id: string;
  /** 
   * @description This will set the Form component label for this Input. Outside of the Form component, this property will be ignored. 
   * */
  label?: string;
  name: string;
  className?: string;
  type?: string;
  placeholder?: string;
  onChange?: any;
  onBlur?: any;
  value?: any;
  maxLength?: number;
  styleOverride?: object;
  inputPrefix?: string;
  /** 
   * @description This will display a custom error message.
   * Outside of the Form component, this property will be ignored.
   * */
  customErrorMessage?: string;
  /** 
   * @description This will determine how the Form component will format the input value.
   * Outside of the Form component, this property will be ignored.
   * @example For phone the format will be (ddd) ddd-dddd. 
   * */
  mask?: "amount" | "ssn" | "date" | "phone" | "ein" | "percent";
  /** 
   * @description This will add the InfoCircle icon at the input Label with some Tooltip text. Outside of the Form component, this property will be ignored. 
   * */
  tooltip?: string;
  onTooltipHover?: () => void;
  formik?: any;
  onKeyUp?: any;
  disabled?: boolean;
  readonly?: boolean;
  onShowOrHide?: (show: boolean) => void;
  helperText?: string;
}

const Input: React.FC<IInput> = ({
  className,
  id,
  type,
  name,
  onChange,
  onBlur,
  placeholder,
  value,
  maxLength,
  styleOverride,
  formik,
  inputPrefix,
  mask,
  onKeyUp,
  disabled,
  readonly,
  helperText,
  onShowOrHide
}) => {
  const [showText, setShowText] = useState(false);
  const inputRef = useRef(null);
  const [cursor, setCursor] = useState(null);
  const [cursorPosFromRight, setCursorPosFromRight] = useState(null);
  const [changed, setChanged] = useState(false);
  const [date, setDate] = useState('');
  const [hasError, setHasError] = useState(false);

  let autocomplete: "on" | "off" = "off";
  if (window.EnableDebug) {
    autocomplete = "on";
  }

  if (mask === "amount") {
    inputPrefix = "$";
  }

  if (mask === "percent") {
    inputPrefix = "%";
  }

  useEffect(() => {
    if (value) {
      setHelperText(value);
    }
  }, []);

  useEffect(() => {
    setHasError(formik?.touched[name] && formik?.errors[name]);
  }, [formik]);

  useEffect(() => {
    if (mask) {
      const input = inputRef.current;
      if (input && type !== "email") {
        input.setSelectionRange(cursor, cursor);
      }
    }
  }, [cursor, value, changed]);

  useEffect(() => {
    if (mask) {
      // between calling the setCursorPosFromRight and here, additional chars can be added; max(0, ...) is added when deleting on position 0
      setCursor(Math.max(0, inputRef?.current?.value?.length - cursorPosFromRight));
      setChanged(false);
    }
  }, [inputRef?.current?.value]);

  type = type || "text"; // Set default to text is not provided

  if (type.toLowerCase() === "password") {
    styleOverride = {
      ...styleOverride,
      paddingInline: "2rem 3.4rem"
    }
  }

  const isSensitive = type === "password";

  const inputPrefixStyle: React.CSSProperties = {
    left: "10px",
    top: "58%",
    transform: "translateY(-50%)",
    position: 'absolute',
    fontSize: "1.5rem",
    fontWeight: 500,
    color: "var(--darkTextColor)"
  };

  if (!placeholder && mask === "date") {
    placeholder = "mm/dd/yyyy";
  }

  const handleChange = (e: any) => {
    setHelperText(e.target.value);
    if (mask) {
      setChanged(true);
      setCursorPosFromRight(inputRef.current.value.length - e.target.selectionStart);
    }
    onChange(e);
  };

  const setHelperText = (text: string) => {
    let dateDigits: string = stripOutNonDigits(text);
    if (dateDigits.length > 8) {
      dateDigits = dateDigits.substring(0, 8);
    }
    if (mask === "date" && dateDigits.length === 8) {
      const formattedDate = moment(`${dateDigits.substring(4)}-${dateDigits.substring(0, 2)}-${dateDigits.substring(2, 4)}`);
      setDate(formattedDate.isValid() ? formattedDate.format('MMMM Do, YYYY') : '');
    } else {
      setDate('');
    }
  };

  if (readonly) {
    styleOverride = {
      ...styleOverride,
      backgroundColor: "#f3f3f3",
      fontStyle: "italic",
      color: "#a3a3a3"
    }
  }

  const handleSensitiveInfoStatusChange = (status: boolean) => {
    if (onShowOrHide) {
      onShowOrHide(showText);
    }
    setShowText(status);
  }

  const helperTextStyle: CSSProperties = {
    color: "var(--darkTextColor)",
    position: "absolute",
    bottom: -20,
    left: 5,
    fontSize: "1.3rem"
  }

  return <>
    <div style={{ position: "relative" }}>
      <div style={inputPrefixStyle}>{inputPrefix}</div>
      <input
        className={`${className || ""} ${hasError ? "inputError" : ""}`}
        type={showText || !isSensitive ? ((type === "password" ? "text" : type) || "text") : "password"}
        name={name}
        id={`${id}_input`}
        value={value || ""}
        style={{ ...styleOverride, ...(inputPrefix && { paddingLeft: 25 }) }}
        onChange={handleChange}
        onBlur={onBlur}
        placeholder={placeholder}
        maxLength={maxLength}
        onKeyUp={(e: any) => {
          if (onKeyUp) {
            onKeyUp(e);
          }
        }}
        disabled={disabled}
        ref={inputRef}
        autoComplete={autocomplete}
        readOnly={readonly || false}
        title={readonly ? "This is a readonly field" : null}
      />
      {isSensitive && <ShowSensitiveInfo id={id} onChange={handleSensitiveInfoStatusChange} />}
      {readonly && <span style={helperTextStyle}>Changes not allowed on this field</span>}
      {!readonly && mask === "date" && date && !hasError && <span style={helperTextStyle}>{date}</span>}
      {!readonly && mask !== "date" && helperText && !hasError && <span style={helperTextStyle}>{helperText}</span>}
    </div>
  </>;
};

export default Input;

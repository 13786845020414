import React from "react";
import BackdropOverlayInner from "./BackdropOverlayInner";
import AddMerchantPopup from "./AddMerchantPopup";
import EditLocationAssociationPopup from "./EditLocationAssociationPopup";
import EditUserAssociationPopup from "./EditUserAssociationPopup";
import ExportReportPopup from "./ExportReportPopup";
import InvitationStatusPopup from "./InvitationStatusPopup";
import InviteApplicantPopup from "./InviteApplicantPopup";
import InviteMerchantPopup from "./InviteMerchantPopup";
import InviteUserPopup from "./InviteUserPopup";
import LocationPopup from "./LocationPopup";
import NewApplicationPopup from "./NewApplicationPopup";
import NotificationPopup from "./NotificationPopup";
import ReportEditFieldsPopup from "./ReportEditFieldsPopup";
import SaveReportPopup from "./SaveReportPopup";
import TableFilterPopup from "./TableFilterPopup";
import EditBusinessInformationPopup from "./EditBusinessInformationPopup";
import EditContactInformationPopup from "./EditMainContactInformationPopup";
import EditAccountAssociationPopup from "./EditAccountAssociationPopup";
import AddNewLocationPopup from "./AddNewLocationPopup";
import DeclineMerchantPopup from "./DeclineMerchantPopup";
import EditLocationPopup from "./EditLocationPopup";
import EditUserPopup from "./EditUserPopup";
import EditUserInformationPopup from "./EditUserInformationPopup";
import LandingPageSettingsPopup from "./LandingPageSettingsPopup";
import LandingPageUrlSettingsPopup from "./LandingPageUrlSettingsPopup";
import LandingPageLogoSettingsPopup from "./LandingPageLogoSettingsPopup";
import UpdatePasswordPopup from "./UpdatePasswordPopup";
import BusinessModelPopup from "./BusinessModelPopup";

import ConfirmationPopup from "./ConfirmationPopup";
import { useAppSelector } from "reducers/Hooks";
import { isPopupOpened } from "utils/helpers";
import { POPUPS } from "utils/constants";
import EditLenderGroupPopup from "./EditLenderGroupPopup";
import AddPartnerPopup from "./AddPartnerPopup";
import ApplicationHistoryPopup from "./ApplicationHistoryPopup";
import EditEstimatedSalesPopup from "./EditEstimatedSalesPopup";
import MerchantHistoryPopup from "./MerchantHistoryPopup";
import EditMerchantBusinessMainContactPopup from "./EditMerchantBusinessMainContactPopup";
import EditPartnerBusinessMainContactPopup from "./EditPartnerBusinessMainContactPopup";
import EditWhitelabelBusinessMainContactPopup from "./EditWhitelabelBusinessMainContactPopup";
import EditLogoPopup from "./EditLogoPopup";
import EditMerchantSlugPopup from "./EditMerchantSlugPopup";
import PartnerHistoryPopup from "./PartnerHistoryPopup";
import EditMerchantLandingPageTemplatePopup from "./EditMerchantLandingPageTemplatePopup";
import DTMApplicationPopup from "./DTMApplication/DTMApplicationPopup";
import DTMNowAvailable from "./DTMNowAvailablePopup";
import ApplicationStatusPopup from "./ApplicationStatusPopup";
import RequestRefundPopup from "./RequestRefundPopup";
import OptimizedAddMerchantPopup from "./OptimizedAddMerchantPopup";

const InnerPopups: React.FC = () => {
  const tableFilterPopup = useAppSelector(state => isPopupOpened(state, POPUPS.TABLE_FILTER));
  const editBusinessInformationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_BUSINESS));
  const confirmationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.CONFIRMATION_INNER));
  const declineMerchantPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.DECLINE_MERCHANT));
  const editAccountAssociationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_ACCOUNT_ASSOCIATION));
  const editLenderGroupPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_LENDER_GROUP));
  const editLocationAssociationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_LOCATION_ASSOCIATION));
  const editLocationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_LOCATION));
  const editContactInformationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_MAIN_CONTACT));
  const editUserAssociationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_USER_ASSOCIATION));
  const editUserInformationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_USER_INFORMATION));
  const editUserPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_USER));
  const exportReportPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EXPORT_REPORT));
  const invitationStatusPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.INVITATION_STATUS));
  const inviteUserPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.INVITE_USER));
  const landingPageLogoSettingsPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.LANDING_PAGE_LOGO_SETTINGS));
  const landingPageSettingsPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.LANDING_PAGE_SETTINGS));
  const landingPageUrlSettingsPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.LANDING_PAGE_URL_SETTINGS));
  const locationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.LOCATION));
  const newApplicationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.NEW_APPLICATION));
  const notificationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.NOTIFICATION));
  const reportEditFieldsPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.REPORT_EDIT_FIELDS));
  const saveReportPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.SAVE_REPORT));
  const updatePasswordPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.UPDATE_PASSWORD));
  const inviteApplicantPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.INVITE_APPLICANT));
  const inviteMerchantPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.INVITE_MERCHANT));
  const addNewLocationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.ADD_NEW_LOCATION));
  const addMerchantPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.ADD_MERCHANT));
  const addPartnerPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.ADD_PARTNER));
  const applicationHistoryPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.APPLICATION_HISTORY));
  const businessModelPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.BUSINESS_MODEL));
  const editMerchantBusinessAndMainContactPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_MERCHANT_BUSINESS_AND_MAIN_CONTACT));
  const editPartnerBusinessAndMainContactPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_PARTNER_BUSINESS_AND_MAIN_CONTACT));
  const editWhitelabelBusinessAndMainContactPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_WHITELABEL_BUSINESS_AND_MAIN_CONTACT));
  const editEstimatedSalesPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_ESTIMATED_SALES));
  const merchantHistoryPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.MERCHANT_HISTORY));
  const editLogoPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_LOGO));
  const editMerchantSlugPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_MERCHANT_SLUG));
  const partnerHistoryPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.PARTNER_HISTORY));
  const editMerchantLandingPageTemplate = useAppSelector((state) => isPopupOpened(state, POPUPS.EDIT_MERCHANT_LANDING_PAGE_TEMPLATE));
  const dtmApplicationPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.DTM_PROCESS));
  const openDTMNowAvailablePopup = useAppSelector((state) => isPopupOpened(state, POPUPS.DTM_NOW_AVAILABLE));
  const applicationStatusPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.APPLICATION_STATUS));
  const requestRefundPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.REQUEST_REFUND));
  const optimizedAddNewMerchantPopup = useAppSelector((state) => isPopupOpened(state, POPUPS.OPTIMIZED_ADD_MERCHANT));

  return <>
    <BackdropOverlayInner />
    {addMerchantPopup && <AddMerchantPopup />}
    {addPartnerPopup && <AddPartnerPopup />}
    {addNewLocationPopup && <AddNewLocationPopup />}
    {declineMerchantPopup && <DeclineMerchantPopup />}
    {editBusinessInformationPopup && <EditBusinessInformationPopup />}
    {editLocationPopup && <EditLocationPopup />}
    {editLocationAssociationPopup && <EditLocationAssociationPopup />}
    {editContactInformationPopup && <EditContactInformationPopup />}
    {editUserPopup && <EditUserPopup />}
    {editAccountAssociationPopup && <EditAccountAssociationPopup />}
    {editUserAssociationPopup && <EditUserAssociationPopup />}
    {editUserInformationPopup && <EditUserInformationPopup />}
    {exportReportPopup && <ExportReportPopup />}
    {invitationStatusPopup && <InvitationStatusPopup />}
    {inviteApplicantPopup && <InviteApplicantPopup />}
    {inviteMerchantPopup && <InviteMerchantPopup />}
    {inviteUserPopup && <InviteUserPopup />}
    {landingPageSettingsPopup && <LandingPageSettingsPopup />}
    {landingPageUrlSettingsPopup && <LandingPageUrlSettingsPopup />}
    {landingPageLogoSettingsPopup && <LandingPageLogoSettingsPopup />}
    {locationPopup && <LocationPopup />}
    {newApplicationPopup && <NewApplicationPopup />}
    {notificationPopup && <NotificationPopup />}
    {reportEditFieldsPopup && <ReportEditFieldsPopup />}
    {saveReportPopup && <SaveReportPopup />}
    {tableFilterPopup && <TableFilterPopup />}
    {updatePasswordPopup && <UpdatePasswordPopup />}
    {/* <DeactivatePopup />
    <MakeLocationPrimaryPopup /> */}
    {confirmationPopup && <ConfirmationPopup inner={true} />}
    {editLenderGroupPopup && <EditLenderGroupPopup />}
    {applicationHistoryPopup && <ApplicationHistoryPopup />}
    {businessModelPopup && <BusinessModelPopup />}
    {editMerchantBusinessAndMainContactPopup && <EditMerchantBusinessMainContactPopup />}
    {editPartnerBusinessAndMainContactPopup && <EditPartnerBusinessMainContactPopup />}
    {editWhitelabelBusinessAndMainContactPopup && <EditWhitelabelBusinessMainContactPopup />}
    {editEstimatedSalesPopup && <EditEstimatedSalesPopup />}
    {merchantHistoryPopup && <MerchantHistoryPopup />}
    {editLogoPopup && <EditLogoPopup />}
    {editMerchantSlugPopup && <EditMerchantSlugPopup />}
    {partnerHistoryPopup && <PartnerHistoryPopup />}
    {editMerchantLandingPageTemplate && <EditMerchantLandingPageTemplatePopup />}
    {dtmApplicationPopup && <DTMApplicationPopup />}
    {openDTMNowAvailablePopup && <DTMNowAvailable />}
    {applicationStatusPopup && <ApplicationStatusPopup />}
    {requestRefundPopup && <RequestRefundPopup />}
    {optimizedAddNewMerchantPopup && <OptimizedAddMerchantPopup />}
  </>;
}

export default InnerPopups;
import { ISettingsView } from "common/interfaces";
import { IPanel } from "common/parts/panel/Panel";
import { IPanelLine } from "common/parts/panel/PanelLine";
import { ITabContent } from "common/parts/tabs/TabContent";
import { Bucket, Gallery, GalleryRemove, Global, GlobalSearch } from "iconsax-react";
import { CSSProperties, useEffect, useState } from "react";
import { useAppSelector } from "reducers/Hooks";
import LandingPageContent from "./content/LandingPageContent";
import { removeProtocol } from "utils/helpers";

const useExperienceSettings = (): ISettingsView => {
  const whitelabel = useAppSelector(state => state.whitelabel);
  const [panels, setPanels] = useState<Array<IPanel>>([]);
  const logoStyle: CSSProperties = { maxWidth: "90%", margin: "auto" };

  useEffect(() => {
    if (whitelabel?.id) {
      const lines: Array<IPanelLine> = [
        { icon: Bucket, label: `#${whitelabel.primary_color} (primary)` },
        { icon: Bucket, label: `#${whitelabel.secondary_color} (secondary)`, iconColor: "secondaryColor" },
        { icon: Global, label: removeProtocol(whitelabel.domain) },
        { icon: GlobalSearch, label: `${removeProtocol(whitelabel.domain)}/merchantSlug` },
        { icon: whitelabel.display_merchant_logo ? Gallery : GalleryRemove, label: `Merchant logos ${whitelabel.display_merchant_logo ? '' : 'not '}allowed on pages` },
        // TODO: add this back when partners have their landing pages available
        // { icon: GlobalSearch, label: `${whitelabel.domain}/partnerSlug` },
        // { icon: whitelabel.display_partner_logo ? Gallery : GalleryRemove, label: `Partner logos ${whitelabel.display_partner_logo ? '' : 'not '}allowed on pages` }
      ];
      setPanels([
        { title: "Experience Settings", lines: lines },
        { title: "Client Portal Logo", panelColor: "primaryColor", textColor: "whiteColor", customContent: <img src={whitelabel?.primary_logo} alt={`${whitelabel?.name} primary logo`} style={logoStyle} /> },
        { title: "Landing Page Logo", panelColor: "whiteColor", textColor: "primaryColor", customContent: <img src={whitelabel?.secondary_logo} alt={`${whitelabel?.name} secondary logo`} style={logoStyle} /> }
      ]);
    }
  }, [whitelabel]);

  const tabContent: ITabContent = {
    sections: [
      { title: "Landing Page Preview", body: <LandingPageContent /> },
    ]
  }

  return { panels, tabContent };
}

export default useExperienceSettings;
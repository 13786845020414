import React from "react";
import NewPopup from "./NewPopup";
import { CloseIcon } from "common";
import { EditMerchantLandingPageTemplate } from "content";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { MerchantHandler } from "actions/MerchantHandler";
import { displayMiniFeedback } from "utils/helpers";
import popUpActions from "reducers/PopUpReducer";

const EditMerchantLandingPageTemplatePopup: React.FC = () => {
  const dispatch = useAppDispatch();
  const merchant = useAppSelector((state) => state.merchant);

  const handleSubmit = (data: any, logo: any) => {
    MerchantHandler().updateTemplate(logo, merchant?.slug, data?.slug, data?.color_theme, data?.industry_template)
    .then(() => {
      displayMiniFeedback("Landing page updated");
      dispatch(popUpActions.closePopup());
    });
  }

  return <>
    <NewPopup style={{ width: 800 }}>
      <>
        <CloseIcon id="editMerchantLandingPageTemplate" color="dark" />
        <EditMerchantLandingPageTemplate
          onSubmit={handleSubmit}
          slug={merchant?.slug}
          primary_logo={merchant?.primary_logo}
          color_theme={merchant?.color_theme?.id}
          industry_template={merchant?.industry_template?.id} />
      </>
    </NewPopup>
  </>;
}

export default EditMerchantLandingPageTemplatePopup;
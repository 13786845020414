import { useState, useEffect, createContext, useMemo, useContext } from 'react';
import { isSmallScreen } from 'utils/helpers';
import RequestHandler from 'classes/RequestHandler';

const FEATURE_FLAGS = {
  ["partners"]: "partners",
  ["loan-application-invite-sms"]: "loan-application-invite-sms",
  ["business-model"]: "business-model",
  ["merchant-information-card"]: "merchant-information-card",
  ["new-merchant-view"]: "new-merchant-view",
  ["new-whitelabel-settings-view"]: "new-whitelabel-settings-view",
  ["new-merchant-settings-view"]: "new-merchant-settings-view",
  ["new-partner-settings-view"]: "new-partner-settings-view",
  ["partner-information-card"]: "partner-information-card",
  ["new-partner-view"]: "new-partner-view",
  ["special-mockups"]: "special-mockups",
  ["new-merchant-dashboard"]: "new-merchant-dashboard",
  ["new-whitelabel-dashboard"]: "new-whitelabel-dashboard",
  ["underwriting-dtm-flow"]: "underwriting-dtm-flow",
  ["dtm-dtc-toggle"]: "dtm-dtc-toggle",
  ["display-optimized-testing-form"]: "display-optimized-testing-form",
}

interface IFeatureFlag {
  name: string,
  is_enabled: boolean
};

interface IFeatureFlagsState {
  flags: IFeatureFlag[];
  setFlags: React.Dispatch<React.SetStateAction<IFeatureFlag[]>>;
  clearFlags: () => void;
}

const defaultContextValue: IFeatureFlagsState = {
  flags: [],
  setFlags: () => { },
  clearFlags: () => { }
};

export const FeatureFlagContext = createContext<IFeatureFlagsState>(defaultContextValue);

export const FeatureFlagProvider = ({ children }) => {
  const [flags, setFlags] = useState<Array<IFeatureFlag>>([]);

  // Method to clear flags
  const clearFlags = () => {
    setFlags([]);
  };

  const value = useMemo(() => ({ flags, setFlags, clearFlags }), [flags]);

  return <FeatureFlagContext.Provider value={value}>{children}</FeatureFlagContext.Provider>;
};

export const useFlag = (flag_name: keyof typeof FEATURE_FLAGS): boolean | undefined => {
  const { flags, setFlags } = useContext(FeatureFlagContext);
  const device_type = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent) || isSmallScreen() ? "MOBILE" : "DESKTOP";
  const [flagValue, setFlagValue] = useState<boolean | undefined>(flags.find(flag => flag.name === flag_name)?.is_enabled || undefined);

  useEffect(() => {
    let isMounted = true; // Flag to check the mounted state

    const fetchFlag = async () => {
      if (flagValue !== undefined) return; // If flagValue is already set, no need to fetch

      try {
        const response = await RequestHandler.makeRequest(`feature-flags/flags/?device_type=${device_type}`, "GET");
        if (isMounted) {
          setFlags(response.data);
          const fetchedFlag = response.data.find(flag => flag.name === flag_name)?.is_enabled;
          setFlagValue(fetchedFlag);
        }
      } catch (error) {
        console.error(error);
        if (isMounted) {
          setFlags(prev => [...prev, { name: flag_name, is_enabled: false }]);
          setFlagValue(false);
        }
      }
    };

    fetchFlag();

    return () => {
      isMounted = false; // Clean up to avoid setting state on unmounted component
    };
  }, [flag_name, flags, setFlags]);

  return flagValue;
};

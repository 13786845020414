import React from "react";
import { HeaderWL, HelpButton, WhiteFooter } from "common";
import { Input, Form, DigitInput } from "common/form";
import { Container, Row, Col } from "react-bootstrap";
import styles from "content/flows/borrowerExperience/BorrowerExperience.module.scss";
import { useNavigate } from "react-router-dom";
import { IFeedback, IStepper } from "common/interfaces";
import BorrowerHandler from "actions/BorrowerHandler";
import { displayFeedback, displayMiniFeedback } from "utils/helpers";
import LocalStorage from "classes/LocalStorage";
import Analytics, { ITracking } from "classes/Analytics";
import borrowerActions from "reducers/BorrowerReducer";
import { useAppDispatch } from "reducers/Hooks";
import { LastFourSSNValidation, PersonalInfoValidation, Schema } from "utils/validation/additionalValidation";

const VerifyDOBandSSN: React.FC<IStepper> = ({ onNext, onBack }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSubmit = async (data: any) => {
    const response = await BorrowerHandler.searchApplicationData(data.date_of_birth, data.ssn);
    if (response.application_id && response.token) {
      LocalStorage.save("vuid", response.token);
      LocalStorage.save("application_id", response.application_id);
      dispatch(borrowerActions.setSkipOffersMatchedSteps(true));
      return navigate("/offers");
    } else if (response.error === "TOO_MANY_ATTEMPTS") {
      displayFeedback({
        title: "Application has been locked",
        body: "Looks like authentication failed 5 times in a row.\nYou can try again in an hour.",
        type: "RESTRICTED",
        buttons: []
      } as IFeedback);
    } else if (response.error === "APP_NOT_FOUND") {
      displayMiniFeedback("Your date of birth and the last four SSN digits do not match. Please make sure they are correct.", true, 10000);
    }
  }

  return (
    <>
      <HeaderWL />
      <main className={styles.borrowerExperienceContainer}>
        <Container fluid className={styles.helpContainer}>
          <Row>
            <Col className={styles.helpCol1}>
              <HelpButton type="BORROWER" />
            </Col>
          </Row>
        </Container>
        <div className={styles.invitationFormContainer}>
          <Form
            id="borrowerExperience_verifyDOBandSSN"
            title="Verify your information"
            submitBtnText="Verify and view offers"
            onFormSubmit={handleSubmit}
            validationSchema={Schema({
              date_of_birth: PersonalInfoValidation.date_of_birth,
              ssn: LastFourSSNValidation
            })}
            values={{
              date_of_birth: "",
              ssn: ""
            }}
            buttonPosition="bottom center"
            inputFields={[
              [
                <Input type="text" name="date_of_birth" id="borrowerExperience_dob" label="Date of birth" mask="date" />,
              ],
              [
                <DigitInput name="ssn" id="borrowerExperience_ssn" label="Last 4 digits of Social Security Number" />
              ]
            ]}
            onBackClick={onBack}
            styleOverride={{
              formContainer: { maxWidth: "330px" }
            }}
          />
        </div>
        <WhiteFooter
          onlyTermsPrivacy
          onPrivacyPolicyClick={() => { Analytics.track({ experience: "borrower", screen: "application_verify_dob_ssn", object: "privacy_policy_link", action: "clicked" } as ITracking, null); }}
          onTermsOfServiceClick={() => { Analytics.track({ experience: "borrower", screen: "application_verify_dob_ssn", object: "terms_service_link", action: "clicked" } as ITracking, null); }} />
      </main>
    </>
  );
};

export default VerifyDOBandSSN;
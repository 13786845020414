import { useImageDimensions } from "hooks/useImageDimensions";
import { useInnerDimensions } from "hooks/useInnerDimensions";
import React, { CSSProperties, useEffect, useState } from "react";
import { useAppSelector } from "reducers/Hooks";
import { getLogoHeightBasedOnDimensions, isSmallScreen } from "utils/helpers";

interface IPoweredBy {
  isPreview: boolean;
  isMobile: boolean;
  width?: number;
}

const PoweredBy: React.FC<IPoweredBy> = ({ isPreview, isMobile, width }) => {
  const whitelabel = useAppSelector(state => state.whitelabel);
  const wlLogoDimensions = useImageDimensions(isMobile ? whitelabel?.secondary_logo : whitelabel?.primary_logo);
  const [innerWidth] = useInnerDimensions();
  const [actualWidth, setActualWidth] = useState(null);

  useEffect(() => {
    if (width && actualWidth !== width) {
      setActualWidth(width);
    } else if (!width) {
      setActualWidth(innerWidth);
    }
  }, [width, innerWidth]);

  let imageStyle: CSSProperties = {
    position: "relative",
    maxHeight: isSmallScreen() ? 50 : 50,
    width: "auto",
    maxWidth: `${actualWidth * 0.35}px`,
    objectFit: "contain",
  };

  if (isMobile) {
    imageStyle = {
      ...imageStyle,
      maxHeight: isSmallScreen() ? 40 : 50,// in case of small screen, it has to be 40 so the logo and the "Powered By" text don't go over LogoHeader's white background
      // maxWidth: "50%",
      maxWidth: `${actualWidth * 0.4}px`,
      width: "auto",
      height: "auto",
      marginRight: 0,
      paddingRight: 0,
      marginTop: 5,
      objectFit: "contain",
    }
  }

  let newStyle: CSSProperties = {
    color: isMobile ? "var(--primaryVariationTextColor)" : "var(--whiteTextColor)",
    position: isPreview ? "relative" : "absolute",
    right: isMobile ? 10 : 20,
    display: "flex",
    gap: 4,
    marginTop: isPreview ? "-9rem" : 0,
    marginBottom: isPreview ? "9rem" : 0,
    marginRight: isPreview ? "-3rem" : 0,
    justifyContent: isPreview ? "right" : "center",
    height: 74,
    textAlign: "right",
    alignItems: isMobile ? "end" : "center",
    flexDirection: isMobile ? "column" : "row",
    zIndex: 2
  }
  if (isMobile) {
    newStyle = {
      ...newStyle,
      top: -74
    }
  } else {
    newStyle = {
      ...newStyle,
      bottom: 0
    }
  }

  return <></>; // Hide powered by logo for now

  // return <>
  //   {whitelabel?.merchant_page_layout === "POWERED_BY" && <div style={newStyle}>
  //     <span style={{ fontSize: isMobile ? 14 : 12, fontWeight: "500" }}>Powered by</span>
  //     <img src={isMobile ? whitelabel?.secondary_logo : whitelabel?.primary_logo} style={{ ...imageStyle, height: getLogoHeightBasedOnDimensions(wlLogoDimensions) }} />
  //   </div>}
  // </>;
}

export default PoweredBy;
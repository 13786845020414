import React from "react";
import { IStepper } from "common/interfaces";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import actions from "reducers/FlowsReducer";
import styles from "../MerchantInvitation.module.scss";
import { ArrowLeft } from "iconsax-react";
import { AppIcon, Container } from "common";
import LocalStorage from "classes/LocalStorage";
import { MerchantHandler } from "actions/MerchantHandler";
import Analytics, { ITracking } from "classes/Analytics";
import { getAPIErrorForTracking } from "utils/helpers";
import EditMerchantLandingPageTemplate from "content/EditMerchantLandingPageTemplate";

const LandingPage: React.FC<IStepper> = ({ onNext, onBack }) => {
  const dispatch = useAppDispatch();
  //const isRevision = useAppSelector(state => state.flows.isRevision); TODO RI: handle revisions
  const landingPageInitialValues = useAppSelector(state => state.flows.merchantInvitation["landingPage"]);
  const vuid = LocalStorage.get<string>("vuid");
  const invitationId = LocalStorage.get<number>("invitationId");
  const [initialValues, setInitialValues] = React.useState(null);

  React.useEffect(() => {
    if (landingPageInitialValues) {
      if (landingPageInitialValues?.domain?.trim() === "") {
        setInitialValues({
          ...landingPageInitialValues,
          slug: landingPageInitialValues.slug
        });
      } else {
        setInitialValues(landingPageInitialValues);
      }
    }
  }, [landingPageInitialValues]);

  const handleSubmit = async (data: any, logo: any) => {
    try {
      await MerchantHandler().saveLandingPage(invitationId, vuid, data?.slug, logo, data?.color_theme, data?.industry_template);
      dispatch(actions.updateMerchantInvitation({ landingPage: data }));
      Analytics.track({ experience: "merchant", screen: "merchant_app_landing_page", object: "form_submission", action: "successful" } as ITracking, null);
      onNext();
    } catch (error) {
      Analytics.track({ experience: "merchant", screen: "merchant_app_landing_page", object: "form_submission", action: "unsuccessful" } as ITracking, { error_name: getAPIErrorForTracking(error) });
    }
  }

  return <>
    {initialValues && <Container>
      <div className={styles.invitationFormInner}>
        <AppIcon clickTrigger={{ id: "landingPage_arrowLeft", onClick: onBack }} size={26} color="var(--darkTextColor)" icon={ArrowLeft} style={{ margin: "0px 0px 0px -30px" }} />
        <EditMerchantLandingPageTemplate
          onSubmit={handleSubmit}
          primary_logo={initialValues?.primary_logo}
          slug={initialValues?.slug}
          color_theme={initialValues?.color_theme}
          industry_template={initialValues?.industry_template}
        />
      </div>
    </Container>}
  </>;
};

export default LandingPage;

import { EmptySpace, Form, Input, SelectDropdown } from "common/form";
import { IDTMFinancialInfo, IStepper } from "common/interfaces";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import actions from "reducers/BorrowerReducer";
import {
  AnnualIncomeValidation, NameValidation,
  Schema, SimpleRequireValidation
} from "utils/validation/additionalValidation";
import { useEffect, useRef, useState } from "react";
import { askForConfirmation, isNullOrUndefinedOrEmpty, scrollTop } from "utils/helpers";
import BorrowerHandler from "actions/BorrowerHandler";
import LocalStorage from "classes/LocalStorage";
import SystemHandler from "actions/SystemHandler";
import { convertDateFormatSlashToDash, stripOutNonDigits } from "utils/formatters";
import { TODAY } from "utils/constants";
import moment from "moment";
import { DTM_VALIDATION_MATRIX, EmploymentDateValidation } from "utils/validation/employmentDateValidation";
import { FormRef } from "common/form/Form";
import { RequireDateInRangeValidation } from "utils/validation/dateValidation";
import popUpActions from "reducers/PopUpReducer";

const DTMFinancialInformation: React.FC<IStepper> = ({ onNext, onBack }) => {
  const dispatch = useAppDispatch();
  const dtmFinancialInformation = useAppSelector(state => state.borrower.dtmFinancialInformation);
  const vuid = LocalStorage.get<string>("vuid");
  const applicationId = LocalStorage.get<number>("application_id");
  const applicationLocked = LocalStorage.get<boolean>("application_locked");
  const formRef = useRef<FormRef>(null);

  const [employmentStatusOptions, setEmploymentStatusOptions] = useState([]);
  const [creditRatingOptions, setCreditRatingOptions] = useState([]);
  const [payFrequencyOptions, setPayFrequencyOptions] = useState([]);
  const [validationSchema, setValidationSchema] = useState<any>({
    employment_status: SimpleRequireValidation,
    annual_income: AnnualIncomeValidation,
    pay_frequency: SimpleRequireValidation,
    company_name: SimpleRequireValidation,
    supervisor_full_name: NameValidation,
    first_payment_date: RequireDateInRangeValidation(TODAY, moment().add(30, 'days').toDate())
  });
  const [initialValues, setInitialValues] = useState(null);
  const [displayEmploymentStartDate, setDisplayEmploymentStartDate] = useState(true);
  const [displayEmploymentEndDate, setDisplayEmploymentEndDate] = useState(true);
  const [requireEmploymentStartDate, setRequireEmploymentStartDate] = useState(false);
  const [requireEmploymentEndDate, setRequireEmploymentEndDate] = useState(false);
  const [inputFields, setInputFields] = useState([]);
  const [buttonPosition, setButtonPosition] = useState<"bottom right" | "bottom center" | "side right">("bottom right");
  const [ready, setReady] = useState(false);

  useEffect(() => {
    Promise.all([
      SystemHandler.getEmploymentStatusOptions(),
      SystemHandler.getPayFrequencyOptions(),
      SystemHandler.getCreditRatingOptions()
    ]).then(responses => {
      setEmploymentStatusOptions(responses[0]);
      setPayFrequencyOptions(responses[1]);
      setCreditRatingOptions(responses[2]);
      setReady(true);
    });
    scrollTop();
  }, []);

  useEffect(() => {
    if (ready) {

      let initValues: IDTMFinancialInfo = {
        employment_status: "",
        credit_rating: null,
        annual_income: 0,
        pay_frequency: "",
        company_name: "",
        supervisor_full_name: "",
        employment_start_date: "",
        employment_end_date: "",
        first_payment_date: ""
      };

      if (dtmFinancialInformation) {
        initValues = dtmFinancialInformation;
      }

      setInitialValues(initValues);
    }
  }, [ready]);

  const adjustEmploymentDateFields = (value: string) => {
    const visibility = DTM_VALIDATION_MATRIX[value];
    setDisplayEmploymentStartDate(visibility.start_date.visible);
    setDisplayEmploymentEndDate(visibility.end_date.visible);
    setRequireEmploymentStartDate(visibility.start_date.required);
    setRequireEmploymentEndDate(visibility.end_date.required);
  }

  useEffect(() => {
    if (dtmFinancialInformation?.employment_status) {
      adjustEmploymentDateFields(dtmFinancialInformation?.employment_status);
    }
  }, [dtmFinancialInformation]);

  useEffect(() => {
    if (ready) {
      const firstPaymentDateField = <Input type="text" name="first_payment_date" id="borrowerExperience_first_payment_date" label="First payment date*" mask="date" tooltip={`Date must be between ${moment().format('MM/DD/YYYY')} and ${moment().add(30, 'days').format('MM/DD/YYYY')}`} />;
      const employmentStartDateField = <Input type="text" name="employment_start_date" id="borrowerExperience_employment_start_date" label={`Employment start date${requireEmploymentStartDate ? "*" : ""}`} mask="date" />;
      const employmentEndDateField = <Input type="text" name="employment_end_date" id="borrowerExperience_employment_end_date" label={`Employment end date${requireEmploymentEndDate ? "*" : ""}`} mask="date" />;

      let fields = [
        [
          <SelectDropdown id="borrowerExperience_employment_status" label="Employment status*" name="employment_status" placeholder="Select" selectOptions={employmentStatusOptions}
            onChange={(value: any) => {
              setValidationSchema({
                ...validationSchema,
                ...EmploymentDateValidation(value)
              });
              adjustEmploymentDateFields(value);

              const companyNameField = document.getElementById("borrowerExperience_company_name_input") as HTMLInputElement;
              if (value === "RETIRED") {
                if (companyNameField && isNullOrUndefinedOrEmpty(companyNameField.value)) {
                  formRef.current?.edit({ company_name: "Retired" });
                }
              } else if (companyNameField && companyNameField.value === "Retired") {
                formRef.current?.edit({ company_name: "" });
              }
            }}
          />,
          <SelectDropdown id="borrowerExperience_pay_frequency" label="Pay frequency*" name="pay_frequency" placeholder="Select" selectOptions={payFrequencyOptions} />
        ],
        [
          <Input type="text" name="annual_income" id="borrowerExperience_annual_income" label="Annual income*" mask="amount" inputPrefix="$" />,
          <Input type="text" name="company_name" id="borrowerExperience_company_name" label="Company name*" />,
        ],
        [
          <Input type="text" name="supervisor_full_name" id="borrowerExperience_supervisor_full_name" label="Supervisor full name*" />,
          !displayEmploymentStartDate && !displayEmploymentEndDate ? firstPaymentDateField :
            (displayEmploymentStartDate ? employmentStartDateField : employmentEndDateField)
        ]
      ];
      setButtonPosition("bottom right");
      if (displayEmploymentStartDate && displayEmploymentEndDate) {
        fields.push([employmentEndDateField, firstPaymentDateField]);
      } else if (displayEmploymentStartDate && !displayEmploymentEndDate) {
        fields.push([firstPaymentDateField]);
        setButtonPosition("side right");
      }
      setInputFields(fields);

    }
  }, [ready, displayEmploymentStartDate, displayEmploymentEndDate]);

  const handleSubmit = async (data: IDTMFinancialInfo) => {
    const payload = {
      ...data,
      annual_income: parseInt(stripOutNonDigits((data.annual_income || 0).toString())),
      employment_start_date: convertDateFormatSlashToDash(data.employment_start_date) || null,
      employment_end_date: convertDateFormatSlashToDash(data.employment_end_date) || null,
      first_payment_date: convertDateFormatSlashToDash(data.first_payment_date)
    }

    if (payload.annual_income < 15000) {
      askForConfirmation(`Are you sure that you have entered the annual (yearly) amount and not the monthly amount?`,
        { text: 'Yes, I am sure', action: () => { dispatch(popUpActions.closePopup()); goToNextScreen(data, payload) } },
        { text: 'No, take me back', action: () => { dispatch(popUpActions.closePopup()) } }, true, null,
        `Looks like the annual amount is under $15,000`,
        "/assets/images/annual_amount.svg"
      );
    } else if (payload.annual_income > 500000) {
      askForConfirmation(`Are you sure that you have entered the annual (yearly) amount?`,
        { text: 'Yes, I am sure', action: () => { dispatch(popUpActions.closePopup()); goToNextScreen(data, payload) } },
        { text: 'No, take me back', action: () => { dispatch(popUpActions.closePopup()) } }, true, null,
        `Looks like the annual amount is over $500,000`,
        "/assets/images/annual_amount.svg"
      );
    } else {
      goToNextScreen(data, payload);
    }
  }

  const goToNextScreen = async (data: IDTMFinancialInfo, payload: any) => {
    if (applicationId && vuid) {
      dispatch(actions.setDTMFinancialInformation(data));
      await BorrowerHandler.saveFinancialInformation(payload, applicationId, vuid);
    }
    onNext();
  }
  return <>
    {ready && initialValues && inputFields.length > 0 && <Form
      id="borrowerExperience_financialInformation"
      title="Financial information"
      submitBtnText="Next"
      onFormSubmit={handleSubmit}
      validationSchema={Schema(validationSchema)}
      values={initialValues}
      buttonPosition={buttonPosition}
      inputFields={inputFields}
      onBackClick={onBack}
      disabledInputFields={applicationLocked}
      ref={formRef}
    />}
  </>;
};

export default DTMFinancialInformation;
import React, { useEffect, useState } from "react";
import BorrowerHandler from "actions/BorrowerHandler";
import LocalStorage from "classes/LocalStorage";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import actions from "reducers/BorrowerReducer";
import { moveObjectToFront } from "utils/helpers";
import _ from "lodash";
import strings from "localization/Strings";
import { sortingMetrics } from "utils/constants";
import Analytics, { ITracking } from "classes/Analytics";
import { useVisitTime } from "hooks/useVisitTime";
import { useNavigate } from "react-router-dom";

let timeFirstOffer;
let timeNoOffers;

export function useOffers() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handlePageLeave = (timeElapsedSeconds: number) => {
        Analytics.track({
            experience: "borrower",
            screen: "application_offers",
            object: "page",
            action: "left"
        } as ITracking, { visit_time: timeElapsedSeconds });
    }

    useVisitTime(handlePageLeave);

    let timeStart;

    useEffect(() => {
        timeStart = Date.now();
    }, []);

    const [progressStatus, setProgressStatus] = React.useState<number>(0);
    const [offerLowestAPRId, setOfferLowestAPRId] = React.useState<number>(0);
    const [offerHighestLoanAmountId, setOfferHIghestLoanAmountId] = React.useState<number>(0);

    // all offers currently loaded from EVEN api - UI can display just some of those offers
    const [offersLoaded, setOffersLoaded] = React.useState<any[]>([]);
    // offers that are saved to redux - if user refreshes page or gets to the offers after already getting them, use these instead of calling API again
    const offersSaved = useAppSelector(state => state.borrower.offers);
    // all offers currently shown - might be less then all offers loaded in case the "Show 5 more" or "Show all" buttons are shown
    const [allOffers, setAllOffers] = useState<Array<any>>([]);

    const [offersInterval, setOffersInterval] = React.useState(null);
    // how many times the api was called for the offers - limit it on 60 s
    const [offersLoads, setOffersLoads] = React.useState(0);
    const whitelabel = useAppSelector(state => state.whitelabel);
    // if borrower goes to offers page after DOB verification, skip the offers matched step
    const skipOffersMatchedStep = useAppSelector(state => state.borrower.skipOffersMatchedStep);

    const text1 = "This can take up to 20 seconds...";
    const text2 = "We're still searching...";
    const text3 = "We were unable to match you to any offers at this time. We will continue trying for 1 hour and will let you know via email if we find any offers for you.";
    const [offersSearchingText, setOffersSearchingText] = useState<string>(text1);

    const noOffers = LocalStorage.get<boolean>("no_offers");

    const [offersMatched, setOffersMatched] = useState<"WAITING" | "FALSE" | "TRUE">(noOffers ? "FALSE" : "WAITING");
    // set to true when user proceeds from "OffersMatched part of the page"
    const [wentToOffers, setWentToOffers] = useState<boolean>(false);

    const [vuid, setVuid] = useState(null);
    const [applicationId, setApplicationId] = useState(null);
    const [selectedSortOption, setSelectedSortOption] = useState("");


    if (!vuid && !applicationId) {
        const vuidTemp = LocalStorage.get<string>("vuid");
        const applicationIdTemp = LocalStorage.get<number>("application_id")
        if (vuidTemp && applicationIdTemp) {
            setVuid(vuidTemp);
            setApplicationId(applicationIdTemp);
        } else {
            navigate(`/${window.localStorage.getItem('merchant-slug') || ''}`);
        }
    }


    // DO NOT DELETE - this is for testing edge cases
    if (process.env.REACT_APP_ENV?.toLowerCase() === "dev" || process.env.REACT_APP_ENV?.toLowerCase() === "development") {
        // uncomment to force offers to a waiting for offers state
        // if (offersMatched !== "WAITING") {
        //   setOffersMatched("WAITING");
        // }
        // if (wentToOffers) {
        //   setWentToOffers(false);
        // }

        // uncomment to force offers to offers matched state
        // if (offersMatched !== "TRUE") {
        //   setOffersMatched("TRUE");
        // }
        // if (wentToOffers) {
        //   setWentToOffers(false);
        // }

        // uncomment to force offers to a no offers state
        // if (offersMatched !== "FALSE") {
        //   setOffersMatched("FALSE");
        // }
        // if (wentToOffers) {
        //   setWentToOffers(false);
        // }
    }


    // if (offersMatched === "WAITING" && !wentToOffers) {
    //   displayLoadingBar("test");
    // } else if (offersMatched !== "WAITING") {
    //   hideLoadingBar();
    // }

    // tracking time to first offer
    useEffect(() => {
        if (allOffers.length >= 1 && !timeFirstOffer) {
            timeFirstOffer = Date.now();
            const timeElapsed = timeFirstOffer - timeStart;

            Analytics.track({
                experience: "borrower",
                screen: "application_offers",
                object: "first_offer",
                action: "received"
            } as ITracking, { time_first_offer: Math.floor(timeElapsed / 1000) });
        }
    }, [allOffers]);

    // tracking time to no offers
    useEffect(() => {
        if (offersMatched === "FALSE" && !timeNoOffers) {
            timeNoOffers = Date.now();
            const timeElapsed = timeNoOffers - timeStart;

            Analytics.track({
                experience: "borrower",
                screen: "application_offers",
                object: "no_offers",
                action: "received"
            } as ITracking, { time_no_offers: Math.floor(timeElapsed / 1000) });
        }
    }, [offersMatched]);

    const handleContinueToOffers = async () => {
        setWentToOffers(true);
        Analytics.track({ experience: "borrower", screen: "application_matched_offers", object: "continue_button", action: "clicked" } as ITracking, null);
    }

    useEffect(() => {
        // This function will be called when the user navigates using the browser's back or forward button
        const handlePopstate = () => {
            // without this, it won't show application locked message the second time you click browser's back button
            window.history.back();
        };

        window.addEventListener('popstate', handlePopstate);

        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, []);

    useEffect(() => {
        if (offersSaved && progressStatus !== 100) {
            // if offers are already saved to redux, progress bar will be reset after going back, so you need to set it again to hide the progress bar
            setProgressStatus(100);
        }
        if (!wentToOffers && (offersSaved || skipOffersMatchedStep)) {
            // if offers are already saved to redux, wentToOffers will be reset after going back
            setWentToOffers(true);
        }
    }, []);

    const showAll = () => {
        setAllOffers(offersLoaded);
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const sortOffers = (sorting: string) => {
        let offersTemp = null;
        switch (sorting) {
            case sortingMetrics[0].value: offersTemp = _.orderBy(allOffers, "apr", 'asc'); break;
            // case sortingMetrics[1].value: offersTemp = _.orderBy(allOffers, "monthly_payment", 'asc'); break;
            case sortingMetrics[1].value: offersTemp = _.orderBy(allOffers, "amount", 'desc'); break;
            case sortingMetrics[2].value: offersTemp = _.orderBy(allOffers, "term", 'asc'); break;
            case sortingMetrics[3].value: offersTemp = _.orderBy(allOffers, "term", 'desc'); break;
            default: offersTemp = _.orderBy(allOffers, "score", 'asc'); break;
        }
        setAllOffers(offersTemp);
    }

    const offerColors: string[] = [
        "var(--greenColor)",
        "var(--secondaryColor)",
        // "var(--primaryVariationColor)",
    ];
    const offerLabels: string[] = [
        "Lowest APR",
        "Highest loan amount",
    ];

    const getOffersLocal = () => {
        BorrowerHandler.getOffers(applicationId, vuid)
            .then(response => {
                if (response && response.results?.length > 0) {
                    const offers: any[] = response?.results;
                    dispatch(actions.setOffers(offers));
                    setOffersMatched("TRUE");
                    // Analytics.track(TRACKING_EVENTS.BORROWER_APPLICATION_OFFERS_RETURNED)
                } else if (response && response.results?.length === 0 && response.x_progress === 100) {
                    setOffersMatched("FALSE");
                    // Analytics.track(TRACKING_EVENTS.BORROWER_APPLICATION_NO_OFFERS_RETURNED)
                }
                setProgressStatus(response.x_progress);
            })
    };

    useEffect(() => {
        // if the user got to the offers after the displayApplicationLockedFeedbackPopup, the offers should be loaded from redux
        if (vuid) {
            getOffersLocal();
            if (!offersInterval && !offersSaved) {
                setOffersInterval(setInterval(() => {
                    getOffersLocal();
                    setOffersLoads(prevOffersLoads => prevOffersLoads + 1);
                }, 1000));

                // stop calling when moving to other page
                return () => clearInterval(offersInterval);
            }
        }
    }, [vuid]);

    // clears interval for getting offers, when all offers are loaded
    useEffect(() => {
        if (progressStatus === 100) {
            clearInterval(offersInterval);
        }
    }, [progressStatus]);

    // clears interval if offersMatched is FALSE - if user clicks browser's back button after no offers
    useEffect(() => {
        if (offersMatched === "FALSE") {
            setProgressStatus(100);
            clearInterval(offersInterval);
            // so the app doesn't try to receive offers again when user clicks browser's back button after no offers
            LocalStorage.save("no_offers", true);
        }
    }, [offersMatched]);

    // clears interval for getting offers after 60 s
    useEffect(() => {
        if (offersLoads >= 20 && offersLoads < 60 && offersSearchingText !== text2) {
            setOffersSearchingText(text2);
        }
        if (offersLoads >= 60) {
            if (offersSearchingText !== text3) {
                setOffersSearchingText(text3);
            }

            clearInterval(offersInterval);
            setProgressStatus(100);
            if (allOffers?.length === 0) {
                setOffersMatched("FALSE");
            }
        }
    }, [offersLoads]);

    useEffect(() => {
        let object = "";
        switch (selectedSortOption) {
            case "APR: lowest to highest": object = "sort_apr_low_high"; break;
            case "Offer amount: highest to lowest": object = "sort_offer_amt_hight_low"; break;
            case "Term: lowest to highest": object = "term_low_high"; break;
            case "Term: highest to lowest": object = "term_high_low"; break;
        }
        if (object) {
            Analytics.track({ experience: "borrower", screen: "application_offers", object: object, action: "successful" } as ITracking, null);
        }

        sortOffers(selectedSortOption);
    }, [selectedSortOption]);

    // show how the offers are shown - labels, colors, order, ...
    useEffect(() => {
        if (offersSaved) {
            setAllOffers(offersSaved);

            // TODO: transfer all conversions down in this file when calling OfferCard (so everything will be in one place), in this useEffect just convert every value that needs conversion, for example: Number(offer.amount)
            const offersToShow = offersSaved.map((offer, i) => {
                return {
                    id: offer.id,
                    amount: Number(offer.amount),
                    term: Number(offer.term),
                    term_unit: offer.term_unit,
                    term_description: offer.term_description,
                    monthly_payment: Number(offer.monthly_payment),
                    monthly_payment_description: offer.monthly_payment_description,
                    apr_description: offer.apr_description,
                    apr: Number(offer.apr),
                    lender_logo_url: offer.lender_logo_url,
                    score: Number(offer.score),
                    pre_approved: offer.pre_approved,
                    pre_qualified: offer.pre_qualified,
                    more_information: offer.more_information,
                    disclaimer: offer.disclaimer,
                    lender_name: offer.lender_name,
                    external_url: offer.external_url,
                    subtype: offer.subtype,
                    subtype_disclaimer: offer.subtype_disclaimer,
                }
            });

            const highestLoanAmount = Math.max(...offersSaved.map(offer => offer.amount));
            const highestLoanAmountIndex = offersSaved.findIndex(offer => offer.amount == highestLoanAmount);
            setOfferHIghestLoanAmountId(offersSaved[highestLoanAmountIndex].id);
            moveObjectToFront(offersToShow, highestLoanAmountIndex);

            const lowestAPR = Math.min(...offersSaved.map(offer => offer.apr));
            const lowestAPRIndex = offersSaved.findIndex(offer => offer.apr == lowestAPR);
            setOfferLowestAPRId(offersSaved[lowestAPRIndex].id);

            if (lowestAPRIndex === 0) {
                // lowestAPR offer is the same as highest loan amount offer
            } else {
                moveObjectToFront(offersToShow, lowestAPRIndex);
            }

            setAllOffers(offersToShow);
        }
    }, [offersSaved]);

    const getOfferLabel = (data: any, i: any) => {
        if (offerLowestAPRId === offerHighestLoanAmountId) {
            if (data.id === offerLowestAPRId) {
                return offerLabels[0] + " & " + offerLabels[1];
            }
        } else {
            if (data.id === offerLowestAPRId) {
                return offerLabels[0];
            } else if (data.id === offerHighestLoanAmountId) {
                return offerLabels[1];
            } else {
                return "";
            }
        }
    };

    const showDisclaimer = () => {
        return <p
            className={`info`}
            style={{ color: "var(--darkTextColor)", textAlign: "center", marginBlockStart: "4rem" }}
        >
            {strings.offersDisclaimer}
        </p>
    }

    const offersRedux = { whitelabel }
    const offersState = { wentToOffers, offersMatched, offersSearchingText, selectedSortOption, progressStatus, allOffers, offersLoaded, offerLowestAPRId, offerHighestLoanAmountId, setSelectedSortOption }
    const offersConstants = { offerColors, text3 }

    return { handleContinueToOffers, showAll, scrollToTop, getOfferLabel, showDisclaimer, offersRedux, offersState, offersConstants }
}

export function offersTracking() {
    const handleSelectOffer = () => {
        Analytics.track({ experience: "borrower", screen: "application_offers", object: "offer", action: "selected" } as ITracking, null);
    }

    const handleAdvertiserDisclosureHover = () => {
        Analytics.track({ experience: "borrower", screen: "application_offers", object: "advertiser_discl_link", action: "hover" } as ITracking, null);
    }

    const handleHelpButtonClick = () => {
        Analytics.track({ experience: "borrower", screen: "application_offers", object: "help_link", action: "clicked" } as ITracking, null);
    }

    const handlePartnerGuaranteeDisclosureHover = () => {
        Analytics.track({ experience: "borrower", screen: "application_offers", object: "partner_guarantee_discl_link", action: "hover" } as ITracking, null);
    }

    const handleRepresentativeExampleHover = () => {
        Analytics.track({ experience: "borrower", screen: "application_offers", object: "rep_example_discl_link", action: "hover" } as ITracking, null);
    }

    const handlePrivacyPolicyClick = () => {
        Analytics.track({ experience: "borrower", screen: "application_offers", object: "privacy_policy_link", action: "clicked" } as ITracking, null);
    }

    const handleTermsOfServiceClick = () => {
        Analytics.track({ experience: "borrower", screen: "application_offers", object: "terms_service_link", action: "clicked" } as ITracking, null);
    }

    return { handleSelectOffer, handleAdvertiserDisclosureHover, handleHelpButtonClick, handlePartnerGuaranteeDisclosureHover, handleRepresentativeExampleHover, handlePrivacyPolicyClick, handleTermsOfServiceClick }
}
import styles from "./OfferCard.module.scss";
import { Button, ToolTip } from "./";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "reducers/Hooks";
import actions from "reducers/BorrowerReducer";
import { IOfferCard } from "./interfaces";
import { getPreApprovedPreQualifiedText } from "utils/helpers";
import Analytics, { ITracking } from "classes/Analytics";
import { formatAmount } from "utils/formatters";

const OfferCard: React.FC<IOfferCard> = (props) => {
  const { label, labelColor, amount, term, term_unit, term_description, monthly_payment,
    monthly_payment_description, apr, apr_description, offerLogo, id, offerInfoVariant,
    pre_approved, pre_qualified, subtype, subtype_disclaimer, onClick } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  let prequalifiedPreapprovedText = getPreApprovedPreQualifiedText(pre_approved, pre_qualified);

  let productTypeText = "";
  switch (subtype) {
    case "personal_loan": productTypeText = "Personal loan • Unsecured"; break;
    case "secured_loan": productTypeText = "Personal loan • Secured"; break;
    case "line_of_credit": productTypeText = "Line of credit"; break;
  }

  return (
    <div className={styles.offerCardContainer} style={{ boxShadow: offerInfoVariant ? "0px 3px 10px var(--primaryColor20Solid)" : "8px 8px 10px var(--primaryColor10Solid)" }} >
      {!offerInfoVariant && label && label !== "" && <div className={styles.offerCardLabel} style={{ background: labelColor }}>
        <h4 className="textSmall">{label}</h4>
      </div>}
      <div className={styles.offerCardLogo}>
        <img src={offerLogo} alt="Offer" />
      </div>
      <div className={styles.border}></div>
      <div className={styles.offerCardInfoHeading}>
        {/* <div className={styles.info}>
          <h4>{term + " " + term_unit.toString().toLowerCase() + (term > 1 ? "s" : "")}</h4>
          <p>{term_description ?? "term of loan"}</p>
        </div>
        <div className={styles.info}>
          <h4>${monthly_payment}</h4>
          <p>{monthly_payment_description ?? "est. monthly payment"}</p>
        </div>
        <div className={styles.info}>
          <h4>{apr}%</h4>
          <p>{apr_description ?? "APR"}</p>
        </div> */}

        <div className={styles.productType}>
          <label>{productTypeText}</label>
          {subtype_disclaimer && <ToolTip
            placement={"top"}
            text={subtype_disclaimer}
            styleOverride={{ marginLeft: "5px" }}
          />}
        </div>
        <h3>{prequalifiedPreapprovedText} loan offer for ${formatAmount(amount.toString())}</h3>
      </div>
      <div className={styles.offerInfoContainer}>
        <div className={styles.info}>
          <h4><span style={{ color: "var(--primaryColor)" }}>${monthly_payment}</span>/month for <span style={{ color: "var(--primaryColor)" }}>{term} </span> {term_unit.toString().toLowerCase() + (term > 1 ? "s" : "")} | <span style={{ color: "var(--primaryColor)" }}>{apr}%</span> {apr_description ?? "APR"}</h4>
        </div>
      </div>
      <div className={styles.offerCardBtn} style={{ justifyContent: offerInfoVariant ? "center" : "flex-start" }} >
        <Button
          id={`${id}_offerCard_select`}
          type="button"
          label="Select"
          onClick={() => {
            dispatch(actions.setOfferInfo(props))
            if (onClick) {
              onClick();
            }
            navigate("/offerSelected");
          }}
        />

        {!offerInfoVariant && <Button
          id={`${id}_offerCard_infoDisclaimer`}
          type="button"
          label="Information & Disclaimer"
          variant="secondary"
          style={{ border: "none", textDecoration: "underline", fontSize: "1.4rem", padding: "1.2rem 0rem 0rem 0rem" }}
          onClick={() => {
            dispatch(actions.setOfferInfo(props))
            Analytics.track({ experience: "borrower", screen: "application_offers", object: "info_disclaimer_button", action: "clicked" } as ITracking, null);
            navigate("/offerInfo");
          }}
        />}
      </div>
    </div>
  );
};

export default OfferCard;
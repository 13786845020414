import { URLHandler } from "common";
import WidgetWrapper from "common/WidgetWrapper";
import { InvitationValidator, LocationBorrower, VerifyDOBandSSN } from "content";
import { BorrowerExperience } from "content/flows";
import { Route } from "react-router-dom";

const BorrowerExperienceRoutes = () => {

  const getElement = () => {
    return <URLHandler
      requireParams={["application_id"]}
      errorMessage="The link to your invitation has expired or is not valid. Please contact your referrer for a reinvite."
      redirectUrl={"/"}
      requireVisitorPass>
      {<InvitationValidator type="APPLICATION" />}
    </URLHandler>;
  }

  const getOffers = () => {
    return <URLHandler
      requireParams={["application_id"]}
      errorMessage="The link to your offers has expired or is not valid. Please fill out a new application"
      redirectUrl={"/"}
      requireVisitorPass>
      <VerifyDOBandSSN path="VerifyDOBandSSN" screen="application_verify_dob" />
    </URLHandler>;
  }

  return <>
    <Route path="/:merchantSlug/borrowerExperience" element={<BorrowerExperience />} />
    <Route path="/:merchantSlug/borrowerExperience/personalInformation" element={<BorrowerExperience />} />
    <Route path="/:merchantSlug/borrowerExperience/contactInformation" element={<BorrowerExperience />} />
    <Route path="/:merchantSlug/borrowerExperience/addressInformation" element={<BorrowerExperience />} />
    <Route path="/:merchantSlug/borrowerExperience/financialInformation" element={<BorrowerExperience />} />
    <Route path="/:merchantSlug/borrowerExperience/downPayment" element={<BorrowerExperience />} />
    <Route path="/:merchantSlug/borrowerExperience/socialSecurity" element={<BorrowerExperience />} />
    <Route path="/:merchantSlug/borrowerExperience/agreement" element={<BorrowerExperience />} />
    <Route path="/:merchantSlug/borrowerExperience/loanInformation" element={<BorrowerExperience />} />
    <Route path="/:merchantSlug/borrowerExperience/confirmation" element={<BorrowerExperience />} />
    <Route path="/:merchantSlug/borrowerExperience/widget" element={<WidgetWrapper />} />
    <Route path="/:merchantSlug/borrowerExperience/widget/:application_id/:vuid" element={<WidgetWrapper />} />
    {/* invitation link to the application */}
    <Route path="/:merchantSlug/borrowerExperience/:application_id" element={getElement()} />
    {/* offers link after completing applications */}
    <Route path="/:merchantSlug/offers/:application_id" element={getOffers()} />
    <Route path="/:merchantSlug/location" element={<LocationBorrower />} />
  </>;
};

export default BorrowerExperienceRoutes;
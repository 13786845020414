import { Input, Form } from "common/form";
import React, { useEffect } from "react";
import { IMainContactBorrower, IStepper } from "common/interfaces";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import actions from "reducers/BorrowerReducer";
import { EmailValidation, PhoneValidation, Schema, emailMaxChars } from "utils/validation/additionalValidation";
import BorrowerHandler from "actions/BorrowerHandler";
import { scrollTop } from "utils/helpers";
import LocalStorage from "classes/LocalStorage";
import Analytics, { ITracking } from "classes/Analytics";
import { formatPhoneNumber, stripOutNonDigits } from "utils/formatters";

const ContactInformation: React.FC<IStepper> = ({ onNext, onBack }) => {
  const dispatch = useAppDispatch();

  const vuid = LocalStorage.get<string>("vuid");
  const applicationId = LocalStorage.get<number>("application_id");
  const applicationLocked = LocalStorage.get<boolean>("application_locked");

  let initialValues = {
    email: "",
    phone_number: ""
  };

  const contactInformation = useAppSelector(state => state.borrower.contactInformation);
  if (contactInformation) {
    // initialValues = contactInformation;
    let phoneNumber = contactInformation.phone_number;
    if (phoneNumber?.length === 10) {
      phoneNumber = formatPhoneNumber(contactInformation.phone_number);
    }

    initialValues = {
      email: contactInformation.email,
      phone_number: phoneNumber,
    };
  }

  const handleSubmit = async (data: IMainContactBorrower) => {
    dispatch(actions.setContactInformation(data));
    const payload = {
      email: data.email,
      phone_number: `${stripOutNonDigits(data.phone_number)}`
    };
    if (applicationId && vuid) {
      dispatch(actions.setContactInformation(payload));
      await BorrowerHandler.saveMainContactInformation(payload, applicationId, vuid);
    }
    onNext();
  }

  useEffect(() => {
    scrollTop();
  }, []);

  const handlePhoneNumberTooltipHover = () => {
    Analytics.track({ experience: "borrower", screen: "application_contact_info", object: "phone_info", action: "hover" } as ITracking, null);
  }

  return (
    <Form
      id="borrowerExperience_contactInformation"
      title="Contact information"
      submitBtnText="Next"
      onFormSubmit={handleSubmit}
      validationSchema={Schema({
        email: EmailValidation,
        phone_number: PhoneValidation
      })}
      values={initialValues}
      buttonPosition="bottom right"
      inputFields={[
        [
          <Input type="email" name="email" id="borrowerExperience_email" label="Email" maxLength={emailMaxChars} />,
          <Input type="text" name="phone_number" id="borrowerExperience_phone_number" label="Phone number" mask="phone" />,
        ]
      ]}
      onBackClick={onBack}
      disabledInputFields={applicationLocked}
      disclaimer="We ask this so that we, our partners and their affiliated companies can contact you about the products and services you inquired about, even if your telephone number is listed on any Do-Not- Call list. Contact may be made through automatic dialing systems, artificial or prerecorded voice messaging, or text message."
    />
  );
};

export default ContactInformation;

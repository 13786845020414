import React from "react";
import styles from "./OffersTable.module.scss";
import { Table } from "common";
import { useParams } from "react-router-dom";
import { IHighlight, IList } from "common/interfaces";
import { OFFER_TYPE } from "utils/constants";
import LoanApplicationHandler from "actions/LoanApplicationHandler";
import actions from "reducers/BorrowerReducer";
import { useAppDispatch } from "reducers/Hooks";
import { formatAPICurrency, formatAPIDate } from "utils/formatters";

const OffersTable: React.FC = () => {
  const { applicantId } = useParams();
  const dispatch = useAppDispatch();

  const getOffers = async (next: string): Promise<IList> => {
    let history = await LoanApplicationHandler.getOffers(next, applicantId);
    history.results = history.results.map(result => {
      return {
        ...result,
        lender: result.lender_name,
        dateTime: formatAPIDate(result.date_time),
        offerType: result.pre_approved ? OFFER_TYPE.PRE_APPROVED : (result.pre_qualified ? OFFER_TYPE.PRE_QUALIFIED : "-"),
        termOfLoan: `${result.term} ${result.term_unit?.toLowerCase()}${result.term > 1 ? 's' : ''}`,
        monthlyPayment: formatAPICurrency(parseFloat(result.monthly_payment)),
        monthlyPaymentTerm: formatAPICurrency(parseFloat(result.monthly_payment)) + "/" + (result.term_unit as string).toLowerCase().substring(0, 2),
        maxLoan: formatAPICurrency(parseFloat(result.amount)),
        apr: `${parseFloat(result.apr).toFixed(2)}% ${result.apr_type}`,
        monthly_payment: parseFloat(result.monthly_payment),
        max_loan: parseFloat(result.amount),
        apr_num: parseFloat(result.apr),
        status: result.status,
        funded_amount: parseFloat(result.funded_amount || "0"),
        fundedAmount: formatAPICurrency(parseFloat(result.funded_amount || "0")),
        highlight: { highlighted: result?.status === "Funded", property: "lender_name" } as IHighlight
      }
    });

    dispatch(actions.setApplicationOffers(history.results));

    return history;
  }

  return <div className={styles.contentContainer}>
    <h2 className={styles.pageTitle} style={{ color: "var(--primaryVariationTextColor)" }}>Offers</h2>
    <div className={styles.tableContainer}>
      <Table
        id="merchantViewApplicant_offers"
        data={getOffers}
        tableBodyStyle={{ minWidth: 1100 }}
        headerWrapperStyle={{ minWidth: 1100 }}
        headers={[
          { label: "Lender", value: "lender", size: 8, sortBy: "lender_name" },
          { label: "Offer type", value: "offerType", size: 8, sortBy: "pre_approved" },
          { label: "Term of loan", value: "termOfLoan", size: 7, sortBy: "term" },
          { label: "Payment", value: "monthlyPaymentTerm", sortBy: "monthly_payment", size: 7 },
          { label: "Max loan", value: "maxLoan", sortBy: "amount", size: 7 },
          { label: "APR", value: "apr", sortBy: "apr", size: 7 },
          { label: "Status", value: "status", size: 5, sortBy: "status" },
          { label: "Funded", value: "fundedAmount", size: 6, sortBy: "funded_amount" },
        ]}
        maxHeight={500}
      />
    </div>
  </div>;
}

export default OffersTable;
import styles from "../BorrowerExperience.module.scss";
import { Button, Container, Link } from "common";
import { ArrowLeft } from "react-bootstrap-icons";
import React, { useEffect } from "react";
import { IAgreement, IStepper } from "common/interfaces";
import actions from "reducers/BorrowerReducer";
import BorrowerHandler from "actions/BorrowerHandler";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import LocalStorage from "classes/LocalStorage";
import { useNavigate } from "react-router-dom";
import { scrollTop } from "utils/helpers";
import appActions from "reducers/AppReducer";
import borrowerActions from "reducers/BorrowerReducer";
import Analytics, { ITracking } from "classes/Analytics";

const Agreement: React.FC<IStepper> = ({ onNext, onBack }) => {
  const dispatch = useAppDispatch();
  const whitelabel = useAppSelector(state => state.whitelabel);

  const vuid = LocalStorage.get<string>("vuid");
  const applicationId = LocalStorage.get<number>("application_id");

  let initialValues = false;

  const navigate = useNavigate();

  const agreement = useAppSelector(state => state.borrower.agreement);
  if (agreement) {
    initialValues = agreement;
  }

  useEffect(() => {
    scrollTop();
  }, []);

  const handleSubmit = async (data: IAgreement) => {
    Analytics.track({ experience: "borrower", screen: "application_agreements", object: "agree_continue_button", action: "clicked" } as ITracking, null);

    if (!agreement) {
      dispatch(actions.setAgreement(data));
      await BorrowerHandler.saveAgreement(data, applicationId, vuid);
    }
    LocalStorage.save("application_locked", true);
    dispatch(borrowerActions.setSkipOffersMatchedSteps(false));
    navigate("/Offers");
    dispatch(appActions.addVisitedStep("offers"));
  }

  const handleEsignAgreementClick = () => {
    Analytics.track({ experience: "borrower", screen: "application_agreements", object: "esign_link", action: "clicked" } as ITracking, null);
  }

  const handleCreditAuthAgreementClick = () => {
    Analytics.track({ experience: "borrower", screen: "application_agreements", object: "credit_auth_link", action: "clicked" } as ITracking, null);
  }

  const handleTermsAgreementClick = () => {
    Analytics.track({ experience: "borrower", screen: "application_agreements", object: "terms_service_link", action: "clicked" } as ITracking, null);
  }

  const handlePrivacyPolicyAgreementClick = () => {
    Analytics.track({ experience: "borrower", screen: "application_agreements", object: "privacy_policy_link", action: "clicked" } as ITracking, null);
  }

  return (
    <Container className={`${styles.invitationFormInner}`}>
      <div className={styles.backIcon}>
        <ArrowLeft
          size={26}
          color="var(--darkTextColor)"
          onClick={onBack}
          className={styles.backIcon}
        />
      </div>
      <h1>Agreements</h1>
      <div className={styles.formBlock}>
        <div className={styles.formInner}>
          <div className={styles.formField}>
            <p
              style={{
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "18px",
                textAlign: "justify",
                color: "var(--darkTextColor)",
              }}
            >
              By clicking Agree and Continue, I hereby consent to the{" "}
              <Link id="agreement_eSignAgreement"
                href="/eSignAgreement"
                openNewTab
                onClick={handleEsignAgreementClick}
              >
                E-Sign Agreement
              </Link>
              , the{" "}
              <Link id="agreement_creditAuthorization"
                href="/creditAuthorization"
                openNewTab
                onClick={handleCreditAuthAgreementClick}
              >
                Credit Authorization Agreement
              </Link>
              , the{" "}
              <Link id="agreement_termsCondition"
                href="/termsConditions"
                openNewTab
                onClick={handleTermsAgreementClick}
              >
                Terms of Service
              </Link>
              {" "}and{" "}
              <Link id="agreement_privacyPolicy"
                href="/privacyPolicy"
                openNewTab
                onClick={handlePrivacyPolicyAgreementClick}
              >
                Privacy Policy
              </Link>
              , and am providing written consent under the FCRA for {whitelabel?.name} and
              its partners to obtain consumer report information from my credit
              profile. I agree to be contacted by {whitelabel?.name} and its partners and
              their affiliated companies via email and/or at the telephone
              number(s) I have provided above to explore various financial
              products and services I inquired about, including contact through
              automatic dialing systems, systems, artificial or pre-recorded
              voice messaging, or text message.
            </p>
          </div>
        </div>
        <div className={styles.formInner}>
          <div
            className={`${styles.formBtnContainer} ${styles.nextBtnContainer}`}>
            <Button
              id="borrowerExperience_agreement_agree"
              type="submit"
              label="Agree and Continue"
              onClick={() => handleSubmit({ is_agreed: true })}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Agreement;

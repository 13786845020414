import { Input, SelectDropdown, Form } from "common/form";
import { IPersonalInformation, IStepper } from "common/interfaces";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import actions from "reducers/BorrowerReducer";
import { PersonalInfoValidation, Schema, SimpleRequireValidation, nameMaxChars } from "utils/validation/additionalValidation";
import BorrowerHandler from "actions/BorrowerHandler";
import { useEffect, useState } from "react";
import { scrollTop } from "utils/helpers";
import LocalStorage from "classes/LocalStorage";
import SystemHandler from "actions/SystemHandler";
import { convertDateFormatSlashToDash } from "utils/formatters";

const PersonalInformation: React.FC<IStepper> = ({ onNext, onBack }) => {
  const dispatch = useAppDispatch();
  const [educationLevelOptions, setEducationLevelOptions] = useState([]);

  useEffect(() => {
    SystemHandler.getEducationLevelOptions()
      .then(response => {
        setEducationLevelOptions(response);
      });
    scrollTop();
  }, [])

  const vuid = LocalStorage.get<string>("vuid");
  const applicationId = LocalStorage.get<number>("application_id");
  const applicationLocked = LocalStorage.get<boolean>("application_locked");

  let initialValues = {
    first_name: "",
    last_name: "",
    date_of_birth: "",
    education_level: "",
  };

  const personalInformation = useAppSelector(state => state.borrower.personalInformation);
  if (personalInformation) {
    initialValues = personalInformation;
  }

  const handleSubmit = async (data: IPersonalInformation) => {
    let payload = {
      first_name: data.first_name,
      last_name: data.last_name,
      date_of_birth: convertDateFormatSlashToDash(data.date_of_birth),
      education_level: data.education_level,
    };

    if (applicationId && vuid) {
      dispatch(actions.setPersonalInformation(data));
      await BorrowerHandler.savePersonalInformation(payload, applicationId, vuid);
      window.parent.postMessage({ applicationId, vuid }, '*');
    }

    onNext();
  }

  return (
    <Form
      id="borrowerExperience_personalInformation"
      title="Personal information"
      submitBtnText="Next"
      onFormSubmit={handleSubmit}
      validationSchema={Schema({
        ...PersonalInfoValidation,
        education_level: SimpleRequireValidation
      })}
      values={initialValues}
      buttonPosition="bottom right"
      inputFields={[
        [
          <Input type="text" name="first_name" id="borrowerExperience_first_name" label="First name" maxLength={nameMaxChars} />,
          <Input type="text" name="last_name" id="borrowerExperience_last_name" label="Last name" maxLength={nameMaxChars} />,
        ],
        [
          <Input type="text" name="date_of_birth" id="borrowerExperience_date_of_birth" label="Date of birth" mask="date" />,
          <SelectDropdown
            id="borrowerExperience_education_level"
            label="Highest education level"
            name="education_level"
            placeholder="Select"
            selectOptions={educationLevelOptions}
          />
        ]
      ]}
      onBackClick={onBack}
      disabledInputFields={applicationLocked}
    />
  );
};

export default PersonalInformation;

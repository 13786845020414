import styles from "./HistoryPopup.module.scss";
import { CloseIcon, Scrollable, Table, TimeZoneMessage } from "common";
import React, { useEffect, useState } from "react";
import { IList, IPopup } from "common/interfaces";
import Popup from "./Popup";
import { useParams } from "react-router-dom";
import LoanApplicationHandler from "actions/LoanApplicationHandler";
import { formatAPIDate, formatFullname } from "utils/formatters";

const ApplicationHistoryPopup: React.FC<IPopup> = () => {
  const { applicantId } = useParams();
  const [details, setDetails] = useState(null);

  useEffect(() => {
    if (applicantId) {
      getApplication();
    }
  }, [applicantId]);

  const getApplication = () => {
    LoanApplicationHandler.get(applicantId)
      .then(response => {
        setDetails(response);
      });
  }

  const getHistory = async (next: string): Promise<IList> => {
    let history = await LoanApplicationHandler.getHistory(next, applicantId);
    history.results = history.results.map(result => {
      return {
        ...result,
        dateTime: formatAPIDate(result.date_time, true, true),
        date_time: new Date(result.date_time)
      }
    });
    return history;
  }

  return (
    <Popup isInner={true}>
      <>
        <CloseIcon id="applicationHistoryPopup" color="dark" />
        <Scrollable>
          <div className={styles.shadowFix}>
            <div className={styles.contentContainer}>
              <h2 className={styles.pageTitle}>
                {`Application history ${details?.first_name ? `• ${formatFullname(details?.first_name, details?.last_name)}` : ""}`}
              </h2>
              <div className={styles.tableContainer}>
                <Table
                  id="merchantViewApplicant_history"
                  data={getHistory}
                  headers={[
                    { label: "Event", value: "event", preventSorting: true },
                    { label: "Date/Time", value: "dateTime", sortBy: "date_time" },
                    { label: "Account", value: "account", preventSorting: true },
                    { label: "User", value: "user", preventSorting: true },
                  ]}
                  maxHeight={450}
                  tableBodyStyle={{ minWidth: 690 }}
                />
              </div>
              <TimeZoneMessage timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone} />
            </div>
          </div>
        </Scrollable>
      </>
    </Popup>
  );
};

export default ApplicationHistoryPopup;

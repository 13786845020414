import EnableCache from "./EnableCache";
import Log from "./Log";
import RequestHandler from "./RequestHandler";
import LocalStorage from "./LocalStorage";

export default class ApiCache {
  /**
   * @summary Check cache first before requesting data from API.
   * @param {string} url Get URL.
   * @default duration 15000
   * @returns API response data.
   */
  static async get(url: string, isAnonymous = false): Promise<any> {
    url = ApiCache.addBusinessModelParam(url);
    try {
      const prevResult = EnableCache.get<any>(url);
      if (!prevResult) {
        const response = await RequestHandler.makeRequest(url, 'GET', null, isAnonymous);
        EnableCache.save(url, response.data, 15000);
        return Promise.resolve(response.data);
      } else {
        Log.debug(`Using cache for ${url}`);
        return Promise.resolve(prevResult);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * @summary Add business model query parameter to an cache entry, so it differentiates same enpoint with different business models inside cache
   * @param {string} url Get URL.
   * @returns modified url
   */
  private static addBusinessModelParam = (url: string): string => {
    const businessModel = LocalStorage.get<string>("business_model");
    if (businessModel === "DTC" || businessModel === "DTM") {
      if (url.includes(`/?`)) {
        url += `&bm=${businessModel}`;
      } else if (url.endsWith("/")) {
        url += `?bm=${businessModel}`;
      } else {
        // example of this is: customer/lender-groups
      }
    }
    return url;
  }

  /**
   * @summary Check cache first before requesting data from API.
   * @param {string} url Get URL.
   * @returns API response data.
   */
  static async getAnonymous(url: string): Promise<any> {
    try {
      const prevResult = EnableCache.get<any>(url);
      if (!prevResult) {
        const response = await RequestHandler.makeRequest(url, 'GET', null, true);
        EnableCache.save(url, response.data, 15000);
        return Promise.resolve(response.data);
      } else {
        Log.debug(`Using cache for ${url}`);
        return Promise.resolve(prevResult);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };
}
import React, { ReactElement, useEffect, useMemo } from "react";
import { HeaderWL, HelpButton, ReCaptchaDisclaimer, Stepper } from "common";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./MerchantInvitation.module.scss";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  MainContactInformation, LandingPage, BusinessInformation,
  AdditionalLocations, ThankYou, MainBusinessAddress
} from "./forms";
import { getFlowStep } from "utils/helpers";
import { IStepper } from "common/interfaces";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import flowsActions from "reducers/FlowsReducer";
import Analytics, { ITracking } from "classes/Analytics";
import { useLoadingBar } from "hooks/useLoadingBar";

const Merchant: React.FC = () => {
  const dispatch = useAppDispatch();
  const isRevision = useAppSelector(state => state.flows.isRevision);

  const STEPS: Array<ReactElement<IStepper>> = useMemo(() => [
    <BusinessInformation path="BusinessInformation" screen="merchant_app_business_info" />, // 0
    <MainBusinessAddress path="MainBusinessAddress" screen="merchant_app_main_address" />, // 1
    <MainContactInformation path="MainContactInformation" screen="merchant_app_main_contact" />, // 2
    <AdditionalLocations path="AdditionalLocations" screen="merchant_app_add_locations" />, // 3
    <LandingPage path="LandingPage" screen="merchant_app_landing_page" />, // 4
    <ThankYou path="ThankYou" screen="merchant_app_thank_you" /> // 5
  ], []);
  const totalStepsCount = useMemo(() => STEPS.length, [STEPS]);

  const navigate = useNavigate();
  const location = useLocation();
  const merchantInvitation = useAppSelector(state => state.flows.merchantInvitation);
  useLoadingBar(merchantInvitation);

  const [step, setStep] = React.useState<number>(0);
  const [progressStatus, setProgressStatus] = React.useState<number>(100 / totalStepsCount);

  const stepHandler = (moveNext: Boolean) => {
    if (step === 2 && merchantInvitation?.mainAddress?.manageMultipleLocations === "no") {
      // bypass additional locations
      Analytics.track({ experience: "merchant", screen: "merchant_app_add_locations", object: "form_submission", action: "bypassed" } as ITracking, null);
    }
    let newStep = moveNext ?
      (step === 2 && merchantInvitation?.mainAddress?.manageMultipleLocations === "no" ? 4 : Math.min(step + 1, STEPS.length - 1)) :
      (step === 4 && merchantInvitation?.mainAddress?.manageMultipleLocations === "no" ? 2 : Math.max(step - 1, 0));
    setStep(newStep);
    goTo(STEPS[newStep].props.path);
  };

  useEffect(() => {
    setStep(getFlowStep(STEPS, 2));
    if (step === 0) {
      goTo(STEPS[step].props.path);
    }
    if (!isRevision) {
      dispatch(flowsActions.clearMerchantInvitation());
      dispatch(flowsActions.updateMerchantInvitation({
        businessInformation: { name: "", website: "", annual_sales: "", monthly_financing_volume: "", average_ticket: "", industry: "", industry_specialty: "" },
        mainAddress: { address1: "", city: "", postal_code: "", state: "", manageMultipleLocations: "" },
        landingPage: { slug: "", primary_logo: null },
        additionalLocations: { location_name: "", address1: "", city: "", postal_code: "", state: "" },
        mainContact: { main_contact_first_name: "", main_contact_last_name: "", main_contact_email: "", main_contact_phone: "" }
      }));
    }
  }, []);

  useEffect(() => {
    setStep(getFlowStep(STEPS, 2));
  }, [location]);

  useEffect(() => {
    setProgressStatus((step + 1) * 100 / totalStepsCount);
  }, [step]);

  const goTo = (path: string) => {
    if (window.location.pathname.toLowerCase() !== `/merchantInvitation/${path}`.toLowerCase()) {
      navigate(`/merchantInvitation/${path}`);
    }
  }

  return <>
    {merchantInvitation && <>
      <HeaderWL instructions="Fill out the information and become a merchant!" />
      <main className={styles.partnerInvitationContainer}>
        <Container fluid className={styles.helpContainer}>
          <Row>
            <Col>
              <HelpButton type="MERCHANT" onClick={() => {
                Analytics.track({ experience: "merchant", screen: "[varies]", object: "help_link", action: "clicked" } as ITracking, { screen: STEPS[step].props.path });
              }} />
            </Col>
          </Row>
        </Container>
        <div className={styles.invitationFormContainer}>
          <Stepper steps={STEPS} step={step} onNext={() => stepHandler(true)} onBack={() => stepHandler(false)} />
        </div>
        {step < 5 && (
          <ProgressBar
            now={progressStatus}
            className={styles.progressBar}
            style={{
              height: "3rem",
              borderRadius: "0",
              zIndex: 999
            }}
          />
        )}
        <ReCaptchaDisclaimer type="INVITATION_FLOWS" />
      </main>
    </>}
  </>;
};

export default Merchant;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IMerchant } from 'common/interfaces';
import { DEFAULT_MAX_LOAN_AMOUNT } from 'utils/constants';

const initialState: IMerchant = {
  id: '',
  name: '',
  domain: '',
  slug: '',
  status: 0,
  created_by: null,
  website: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  postal_code: '',
  primary_logo: '',
  secondary_logo: '',
  locations: [],
  annual_sales: 0,
  average_ticket: 0,
  monthly_financing_volume: 0,
  industry: 0,
  industry_specialty: 0,
  main_contact_first_name: '',
  main_contact_last_name: '',
  main_contact_email: '',
  main_contact_phone: '',
  external_id: '',
  lender_group: null,
  max_loan_amount: 250000,
  whitelabel: null,
  partner: null,
  programs: ["DTC"],
  submitted_count: 0,
  submitted_total_amount: 0,
  approved_count: 0,
  funded_count: 0,
  funded_total_amount: 0,
  approval_rate: 0,
  conversion_rate: 0,
  average_ticket_amount: 0,
  color_theme: null,
  industry_template: null
};

const counterSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    initializeMaxLoanAmount: (state) => {
      state.max_loan_amount = DEFAULT_MAX_LOAN_AMOUNT;
    },
    update: (state, action: PayloadAction<any>) => {
      state = Object.assign(state, action.payload);
    },
    clear: (state) => {
      state = Object.assign({}, initialState);
    },
  }
});

export const merchantReducer = counterSlice.reducer;
export default counterSlice.actions;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IOfferCard } from 'common/interfaces';
import Log from "classes/Log";

const counterSlice = createSlice({
  name: 'borrower',
  initialState: {
    loanInformation: null,
    personalInformation: null,
    contactInformation: null,
    addressInformation: null,
    dtcFinancialInformation: null,
    dtmFinancialInformation: null,
    downPaymentInformation: null,
    socialSecurity: null,
    additionalInformation: null,
    agreement: null,
    lender_type: null,
    offerInfo: null,
    offers: null,
    skipOffersMatchedStep: false,
    applicationOffers: [],// for viewing applications in admin panel
  },
  reducers: {
    setLenderType: (state, action: PayloadAction<string>) => {
      state.lender_type = action.payload;
    },
    setLoanInformation: (state, action: PayloadAction<any>) => {
      state.loanInformation = action.payload;
    },
    setPersonalInformation: (state, action: PayloadAction<any>) => {
      state.personalInformation = action.payload;
    },
    setContactInformation: (state, action: PayloadAction<any>) => {
      state.contactInformation = action.payload;
    },
    setAddressInformation: (state, action: PayloadAction<any>) => {
      state.addressInformation = action.payload;
    },
    setDTCFinancialInformation: (state, action: PayloadAction<any>) => {
      state.dtcFinancialInformation = action.payload;
    },
    setDTMFinancialInformation: (state, action: PayloadAction<any>) => {
      state.dtmFinancialInformation = action.payload;
    },
    setDownPaymentInformation: (state, action: PayloadAction<any>) => {
      state.downPaymentInformation = action.payload;
    },
    setSocialSecurity: (state, action: PayloadAction<any>) => {
      state.socialSecurity = action.payload;
    },
    setAdditionalInformation: (state, action: PayloadAction<any>) => {
      state.additionalInformation = action.payload;
    },
    setAgreement: (state, action: PayloadAction<any>) => {
      state.agreement = action.payload;
    },
    setOfferInfo: (state, action: PayloadAction<IOfferCard>) => {
      state.offerInfo = action.payload;
    },
    setOffers: (state, action: PayloadAction<any>) => {
      state.offers = action.payload;
    },
    clearApplication: (state) => {
      state.loanInformation = null;
      state.personalInformation = null;
      state.contactInformation = null;
      state.addressInformation = null;
      state.dtcFinancialInformation = null;
      state.dtmFinancialInformation = null;
      state.socialSecurity = null;
      state.additionalInformation = null;
      state.agreement = null;
      state.lender_type = null;
      state.offerInfo = null;
      state.offers = null;
      state.downPaymentInformation = null;
      Log.info("clearing application");
    },
    setSkipOffersMatchedSteps: (state, action: PayloadAction<boolean>) => {
      state.skipOffersMatchedStep = action.payload;
    },
    setApplicationOffers: (state, action: PayloadAction<any>) => {
      state.applicationOffers = action.payload;
    },
  }
})

export const borrowerReducer = counterSlice.reducer;
export default counterSlice.actions;

import React from "react";

interface ISwitch {
  checked: boolean;
  onChange: (event: any, index: number) => void;
  index: number;
  alwaysOn?: boolean;
  backgroundColor?: string;
}

const Switch: React.FC<ISwitch> = ({ checked, onChange, index, alwaysOn, backgroundColor }) => {
  return <>
    <label className="switch">
      <input type="checkbox" defaultChecked={checked} onChange={(e) => { onChange(e, index) }} />
      <span className={`slider${alwaysOn ? " alwaysOn" : ""}`} style={{ backgroundColor: backgroundColor ? backgroundColor : "" }}></span>
    </label>
  </>;
}

export default Switch;
import { Input, SelectDropdown, Form } from "common/form";
import { IStepper } from "common/interfaces";
import actions from "reducers/BorrowerReducer";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import {
  displayApplicationLockedFeedbackPopup, isSmallScreen,
  prepareNewApplication, scrollTop
} from "utils/helpers";
import { LoanAmountValidation, Schema, SimpleRequireValidation } from "utils/validation/additionalValidation";
import BorrowerHandler from "actions/BorrowerHandler";
import { useEffect, useState } from "react";
import LocalStorage from "classes/LocalStorage";
import { useNavigate } from "react-router-dom";
import SystemHandler from "actions/SystemHandler";
import { DEFAULT_MAX_LOAN_AMOUNT } from "utils/constants";
import Analytics, { ITracking } from "classes/Analytics";
import { formatAPICurrency, stripOutNonDigits } from "utils/formatters";
import useMerchant from "hooks/useMerchant";

const LoanInformation: React.FC<IStepper> = ({ onNext, onBack }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const applicationLocked: boolean = LocalStorage.get<boolean>("application_locked");
  const visitedSteps: string[] = useAppSelector(state => state.app.borrowerVisitedSteps) ?? [];
  const locationId: string = LocalStorage.get<any>("selected_location")?.id;
  const merchant = useMerchant();
  const loanInformation = useAppSelector(state => state.borrower.loanInformation);
  const vuid = LocalStorage.get<string>("vuid");
  const applicationId = LocalStorage.get<number>("application_id");

  const [loanPurposeOptions, setLoanPurposeOptions] = useState([]);
  const [initialValues, setInitialValues] = useState(null);
  const [validationSchema, setValidationSchema] = useState(null);

  useEffect(() => {
    if (loanPurposeOptions.length > 0) {
      let initValues = {
        loan_amount: "",
        loan_purpose: "",
      };

      if (loanInformation) {
        initValues = loanInformation;
      }

      let valSchema = Schema({
        loan_amount: LoanAmountValidation(merchant?.max_loan_amount || DEFAULT_MAX_LOAN_AMOUNT),
        loan_purpose: SimpleRequireValidation
      });
      if (loanPurposeOptions.length === 1 || merchant?.programs.includes("DTM")) {
        valSchema = Schema({
          loan_amount: LoanAmountValidation(merchant?.max_loan_amount || DEFAULT_MAX_LOAN_AMOUNT),
        });
      }

      setInitialValues(initValues);
      setValidationSchema(valSchema);
    }
  }, [merchant, loanPurposeOptions]);

  useEffect(() => {
    if (applicationLocked && visitedSteps.length > 0 && visitedSteps[visitedSteps.length - 1].toLowerCase() === "offers") {
      displayApplicationLockedFeedbackPopup(handleNewApplication, () => { navigate("/Offers") });
    }
    scrollTop();
  }, []);

  useEffect(() => {
    if (applicationLocked) {
      // even though application locked feedback popup already redirects you back to offers, doing it here immediately serves the purpose of changing url back to offers ASAP, so it doesn't look weird while popup is opened
      // if application is locked, you can land on loan information only by typing in url directly or clicking back on offers, so this will not affect other edge cases
      navigate("/Offers", { replace: false });
    }

    SystemHandler.getLoanPurposeOptions().then(response => { setLoanPurposeOptions(response) });
  }, []);

  const handleNewApplication = () => {
    prepareNewApplication();
    navigate(`/${merchant?.slug}/location`);
  };

  const handleSubmit = async (data: any) => {
    let loanAmount = data.loan_amount;
    if (typeof loanAmount === "string") {
      loanAmount = parseFloat(stripOutNonDigits(data.loan_amount))
    }

    let payload = {
      location_id: locationId,
      loan_amount: loanAmount,
      loan_purpose: data.loan_purpose
    };

    if (applicationId && vuid) {
      await BorrowerHandler.updateLoanInformation(payload, applicationId, vuid)
      dispatch(actions.setLoanInformation(data));
    } else {
      dispatch(actions.setLoanInformation(data));
      const response = await BorrowerHandler.saveLoanInformation(payload);
      LocalStorage.save("application_id", response.id);
      LocalStorage.save("vuid", response["visitor_token"]);
      // since selected_location is needed only on LoanInformation when creating new application, you can already delete it here - even without deleting, it should work fine
      LocalStorage.remove("selected_location");
      LocalStorage.remove("equal_housing");
    }

    if ((loanPurposeOptions.length === 1 && loanPurposeOptions[0].id === "HOME_IMPROVEMENT") || data.loan_purpose === "HOME_IMPROVEMENT") {
      LocalStorage.save("equal_housing", true);
    } else {
      LocalStorage.remove("equal_housing");
    }

    onNext();
  }

  const handleLoanAmountTooltipHover = () => {
    Analytics.track({ experience: "borrower", screen: "application_loan_info", object: "loan_amount_info", action: "hover" } as ITracking, null);
  }

  return <>
    {initialValues && validationSchema && <Form
      id="borrowerExperience_loanInformation"
      buttonPosition={isSmallScreen() ? "bottom center" : "side right"}
      onFormSubmit={handleSubmit}
      title="Loan information"
      submitBtnText="Next"
      validationSchema={validationSchema}
      values={initialValues}
      styleOverride={{ formHelp: { marginTop: "20px", maxWidth: "70%" } }}
      inputFields={loanPurposeOptions.length > 1 && !merchant?.programs.includes("DTM") ? [
        [
          <Input type="text" name="loan_amount" id="borrowerExperience_loan_amount" label="Loan amount" mask="amount" inputPrefix="$" tooltip={`Loan amount should be between $1,000 and ${formatAPICurrency(merchant?.max_loan_amount || DEFAULT_MAX_LOAN_AMOUNT, false)}`} onTooltipHover={handleLoanAmountTooltipHover} />,
          <SelectDropdown id="borrowerExperience_loan_purpose" label="Loan purpose" name="loan_purpose" placeholder="Select" selectOptions={loanPurposeOptions} />
        ]
      ] : [
        [
          <Input type="text" name="loan_amount" id="borrowerExperience_loan_amount" label="Loan amount" mask="amount" inputPrefix="$" tooltip={`Loan amount should be between $1,000 and ${formatAPICurrency(merchant?.max_loan_amount || DEFAULT_MAX_LOAN_AMOUNT, false)}`} />,
        ]
      ]}
      formHelp="Checking for loan offers is free and will NOT affect your credit score"
      disabledInputFields={applicationLocked}
    />}
  </>;
};

export default LoanInformation;

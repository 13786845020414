import styles from "./LocationPopup.module.scss";
import { Button, CloseIcon, LocationSelector, SearchBar } from "common";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import Popup from "./Popup";
import { useEffect, useRef, useState } from "react";
import { IMerchantLocation, IPopup } from "common/interfaces";
import { POPUPS } from "utils/constants";
import appActions from "reducers/AppReducer";
import popUpActions from "reducers/PopUpReducer";
import { SearchBarRef } from "common/SearchBar";
import _ from "lodash";
import Analytics, { ITracking } from "classes/Analytics";

const LocationPopup: React.FC<IPopup> = () => {
  const dispatch = useAppDispatch();
  const [keyword, setKeyword] = useState<string>("");
  const searchBarRef = useRef<SearchBarRef>(null);
  const selectedLocation = useAppSelector(state => state.app.selectedLocation);

  const clearKeyword = () => {
    setKeyword("");
    searchBarRef?.current?.clearSearch();
  }

  useEffect(() => {
    if (keyword) {
      Analytics.track({ experience: "portal", screen: "location_change", object: "search", action: "initiated" } as ITracking);
    }
  }, [keyword]);

  return (
    <Popup isInner={true} >
      <>
        <CloseIcon id="locationPopup" color="dark" />
        <h2>Select your location</h2>
        <div className={styles.searchBlock}>
          <div>
            <SearchBar
              id="locationPopup_search"
              placeholder="Search location"
              onSearch={setKeyword}
              ref={searchBarRef}
              onClearClick={() => {
                Analytics.track({ experience: "portal", screen: "location_change", object: "clear_search", action: "clicked" } as ITracking);
              }} />
          </div>
          <div>
            <Button
              id="locationPopup_addLocation"
              type="button"
              label="Add location"
              onClick={() => {
                Analytics.track({ experience: "portal", screen: "location_change", object: "add_location_button", action: "clicked" } as ITracking);
                dispatch(popUpActions.openPopup(POPUPS.ADD_NEW_LOCATION));
              }}
            />
          </div>
        </div>
        <div className={styles.tableContainer}>
          <LocationSelector
            id="locationPopup_selector"
            onSelection={(location: IMerchantLocation) => {
              clearKeyword();
              if (location) {
                Analytics.track({ experience: "portal", screen: "location_change", object: "location", action: "selected" } as ITracking);
                dispatch(appActions.selectLocation(location));
              } else {
                dispatch(appActions.selectLocation(null));
              }
            }}
            keyword={keyword}
            selectedLocationId={selectedLocation?.id}
            displayAll
          />
        </div>
      </>
    </Popup>
  );
};

export default LocationPopup;

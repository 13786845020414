import { IUser, ICreateUser, IList } from "common/interfaces";
import { ENABLE_FINANCING_API_INSTANCE, ENABLE_FINANCING_API_INSTANCE_NO_TOKEN } from "./ActionConstants";
import ErrorHandler from "./ErrorHandler";
import Observer, { EVENTS } from "classes/Observer";
import { MerchantHandler } from "./MerchantHandler";
import Analytics, { ITracking } from "classes/Analytics";
import { PartnerHandler } from "./PartnerHandler";
import { getStatus } from "utils/helpers";

export default class UserHandler {
  /**
   * @description Create new white label user
   * @param {ICreateUser} user User details.
   * @param {boolean} inviteUserAfterCreation Invite user after creation?
   * @returns API response.
   */
  static createForWL = async (user: ICreateUser, inviteUserAfterCreation: boolean): Promise<any> => {
    try {
      let url = "user/whitelabel/create-user/";
      if (inviteUserAfterCreation) {
        url = url.AddQuerystring("invite", "true");
      }
      const response = await ENABLE_FINANCING_API_INSTANCE.post(url, user);
      Observer.trigger(EVENTS.USER_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Create new merchant user
   * @param {ICreateUser} user User details.
   * @param {boolean} inviteUserAfterCreation Invite user after creation?
   * @returns API response.
   */
  static createForMerchant = async (user: ICreateUser, inviteUserAfterCreation: boolean): Promise<any> => {
    try {
      let url = `user/merchant/create-user/`;
      if (user.merchant) {
        url = `user/merchant/${user.merchant}/create/`;
      }
      if (inviteUserAfterCreation) {
        url = url.AddQuerystring("invite", "true");
      }
      const response = await ENABLE_FINANCING_API_INSTANCE.post(url, user);
      Observer.trigger(EVENTS.USER_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Create new partner user
   * @param {ICreateUser} user User details.
   * @param {boolean} inviteUserAfterCreation Invite user after creation?
   * @returns API response.
   */
  static createForPartner = async (user: ICreateUser): Promise<any> => {
    try {
      let url = `users/?invite=true`;
      const response = await ENABLE_FINANCING_API_INSTANCE.post(url, user);
      Observer.trigger(EVENTS.USER_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Update user
   * @param {IUser} user User details.
   * @returns API response.
   */
  static updateProfile = async (user: IUser): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.patch(`user/profile/`, user);
      Observer.trigger(EVENTS.USER_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Change user password
   * @param {number} userId User id.
   * @returns API response.
   */
  static updatePassword = async (userPasswordChange: any, userId: number): Promise<any> => {
    try {

      const response = await ENABLE_FINANCING_API_INSTANCE.patch(`user/profile/change_password/${userId}/`, userPasswordChange);
      Observer.trigger(EVENTS.USER_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }


  /**
   * @description Request user profile from Api
   * @returns Promise with user profile.
   */
  static getProfile = async (): Promise<IUser | null> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.get(`user/profile/`);
      try {
        Analytics.setIdentity(response?.data?.id, {
          id: response?.data?.id,
          first_name: response?.data?.first_name,
          last_name: response?.data?.last_name,
          email: response?.data?.email,
          user_type: response?.data?.user_type
        });
      } catch {
        // ignore errors since this is just logging
      }

      if (response?.data?.user_type === "MERCHANT" && response.data?.customer?.slug) {
        await MerchantHandler().get(response?.data?.customer?.slug);
      } else if (response?.data?.user_type === "PARTNER" && response.data?.customer?.slug) {
        await PartnerHandler().get(response?.data?.customer?.slug);
      }

      return Promise.resolve(response.data as IUser);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Request User Events from Api
   * @returns Promise with call object.
   */
    static getUserEvents = async (): Promise<Object | any> => {
      try {
        const response = ENABLE_FINANCING_API_INSTANCE.get('/user-events/')

        return Promise.resolve(response as Object);

      } catch (error) {
        ErrorHandler(error);
        return Promise.reject(error.response);
      }
    }


  /**
   * @description Get list of users
   * @param {string} next Used to get the next page.
   * @param {string} user_type User type. MERCHANT | WHITELABEL
   * @param {string} merchant_id Merchant Id.
   * @param {string} partner_id Partner Id.
   * @param {number} limit Numer of records.
   * @returns List of users.
   */
  static getAll = async (next: string, user_type: "WHITELABEL" | "PARTNER" | "MERCHANT", merchant_id: string, partner_id: string, limit?: number): Promise<IList> => {
    try {
      limit = limit || 30;
      let url = `user/whitelabel/user/?offset=0&limit=${limit}`;
      if (user_type === "MERCHANT") {
        url = `user/merchant/user/?offset=0&limit=${limit}`
      } else if (user_type === "PARTNER" && merchant_id) {
        url = `users/?merchant=${merchant_id}&offset=0&limit=${limit}`;
      } else if (user_type === "PARTNER") {
        url = `users/?offset=0&limit=${limit}`
      } else if (user_type === "WHITELABEL" && merchant_id) {
        url = `user/merchant/${merchant_id}/user/?offset=0&limit=${limit}`;
      } else if (user_type === "WHITELABEL" && partner_id) {
        url = `users/?partner=${partner_id}&offset=0&limit=${limit}`;
      }
      url = next || url;
      const response = await ENABLE_FINANCING_API_INSTANCE.get(url);
      return Promise.resolve({
        ...response.data,
        originalUrl: url
      } as IList);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Get notifications.
   * @param {number} user_id User Id.
   * @returns Notifications.
   */
  static getNotificationSettings = async (user_id: number): Promise<Array<any>> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.get(`notifications/user/${user_id}/assigned/?offset=0&limit=5000`);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Subscibe to a notification.
   * @param {number} notification_id Id.
   * @param {string} notification_name Name.
   * @returns Notifications.
   */
  static subscribeToNotification = async (notification_id: number, notification_name: string): Promise<Array<any>> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.patch(`notifications/${notification_id}/subscribe/`);
      Analytics.track({ experience: "portal", screen: "settings_notifications", object: "notification", action: "activated" } as ITracking, { notification_name });
      Observer.trigger(EVENTS.NOTIFICATIONS_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Unsubscibe to a notification.
   * @param {number} notification_id Id.
   * @param {string} notification_name Name.
   * @returns Notifications.
   */
  static unsubscribeFromNotification = async (notification_id: number, notification_name: string): Promise<Array<any>> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.patch(`notifications/${notification_id}/unsubscribe/`);
      Analytics.track({ experience: "portal", screen: "settings_notifications", object: "notification", action: "deactivated" } as ITracking, { notification_name });
      Observer.trigger(EVENTS.NOTIFICATIONS_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Get group notifications.
   * @returns Notifications.
   */
  static getGroupNotificationSettings = async (): Promise<Array<any>> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.get(`notifications/group-assigned/?group_name=Merchant+Admin`);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Get notification.
   * @param {string} notification_codename Notification codename.
   * @param {string} vuid vuid sent on the invitation email.
   * @returns Notification.
   */
  static getNotification = async (notification_codename: string, vuid: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE_NO_TOKEN.get(`notifications/${notification_codename}?vuid=${vuid}`);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Update merchant's user - This endpoint is for whitelabel users updating merchant users
   * @param {string} merchant_id Merchant Id.
   * @param {number} user_id User Id.
   * @returns Response.
   */
  static updateMerchantUserForWL = async (merchant_id: string, user_id: number, data: any): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.patch(`user/merchant/${merchant_id}/user/${user_id}/update/`, data);
      Observer.trigger(EVENTS.USER_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Update merchant or partner user
   * @param {number} user_id User Id.
   * @param {string} merchantSlug Merchant slug.
   * @param {string} partnerSlug Partner slug.
   * @returns Response.
   */
  static update = async (user_id: number, data: any, merchantSlug?: string, partnerSlug?: string): Promise<any> => {
    try {
      let url = "";
      if (!partnerSlug && !merchantSlug) {
        url = `users/${user_id}/`;
      } else if (merchantSlug) {
        const merchant = await MerchantHandler().get(merchantSlug);
        if (merchant?.id) {
          url = `user/merchant/${merchant?.id}/user/${user_id}/update/`;
        }
      } else if (partnerSlug) {
        const partner = await PartnerHandler().get(partnerSlug);
        if (partner?.id) {
          url = `users/${user_id}/?partner=${partner.id}`;
        }
      }
      const response = await ENABLE_FINANCING_API_INSTANCE.patch(url, data);
      Observer.trigger(EVENTS.USER_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Change status merchant's user
   * @param {number} user_id User Id.
   * @param {string} status Status.
   * @returns Response.
   */
  static changeStatusMerchantUser = async (user_id: number, status: "Active" | "Deactivated"): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.patch(`user/merchant/user/${user_id}/update/`, { status: status.toUpperCase() });
      Observer.trigger(EVENTS.USER_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Change status partner's user
   * @param {number} user_id User Id.
   * @param {string} status Status.
   * @param {string} partnerId Partner id.
   * @returns Response.
   */
  static changeStatusPartnerUser = async (user_id: number, status: "Active" | "Deactivated", partnerId: string): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.patch(`users/${user_id}/?partner=${partnerId}`, { status: status.toUpperCase() });
      Observer.trigger(EVENTS.USER_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }

  /**
   * @description Change status white label's user
   * @param {number} user_id User Id.
   * @param {string} status Status.
   * @returns Response.
   */
  static changeStatusWhiteLabelUser = async (user_id: number, status: "Active" | "Deactivated"): Promise<any> => {
    try {
      const response = await ENABLE_FINANCING_API_INSTANCE.patch(`user/whitelabel/user/${user_id}/update/`, { status: status.toUpperCase() });
      Observer.trigger(EVENTS.USER_UPDATED);
      return Promise.resolve(response.data);

    } catch (error) {
      ErrorHandler(error);
      return Promise.reject(error.response);
    }
  }
}
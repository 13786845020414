import { IUSPSAddress, IUSPSAddressResponse } from "common/interfaces";
import { convert } from "xmlbuilder2";
import { USPS_API_INSTANCE, SMARTY_API_INSTANCE } from "./ActionConstants";
import { buildAddressXml, moveAddressLine2toline1 } from "utils/validation/addressValidation";

export const getUSPSAddress = async (address: IUSPSAddress): Promise<IUSPSAddress> => {

  try {
    const xml = buildAddressXml(address);
    let url = "ShippingAPI.dll?API=Verify&xml=" + encodeURIComponent(xml);
    const response = await USPS_API_INSTANCE.get(url);
    const obj = convert(response.data, { format: "object" });

    if (typeof obj === "object") {
      const objTyped: IUSPSAddressResponse = obj as unknown as IUSPSAddressResponse;
      if (objTyped.AddressValidateResponse?.Address?.Error) {
        throw new Error(objTyped.AddressValidateResponse?.Address?.Error?.Description);
      } else if (objTyped.AddressValidateResponse?.Address?.City) {
        const parsedAddress = moveAddressLine2toline1(objTyped.AddressValidateResponse?.Address);
        return Promise.resolve(parsedAddress);
      }
    } else {
      throw new Error("Address Not Found.");
    }

  } catch (error) {
    //displayFeedback({ body: "Error validating address", type: "UNPLUGGED" } as IFeedback);
    return Promise.reject(error.response);
  }
}

/* Smarty uses USPS behind the scenes */
export const getSmartyAddresses = async (address: IUSPSAddress): Promise<any> => {
  try {
    if (process.env.REACT_APP_SMARTYSTREETS_AUTOCOMPLETE_ENABLED && process.env.REACT_APP_SMARTYSTREETS_AUTOCOMPLETE_ENABLED === "false") {
      return Promise.resolve([]);
    }

    let url = "lookup?key=" + process.env.REACT_APP_SMARTY_API_KEY + "&search=" + (address.Address1 || "");
    const response = await SMARTY_API_INSTANCE.get(url);

    return Promise.resolve(response.data.suggestions);
  } catch (error) {
    return Promise.reject(error.response);
  }
}
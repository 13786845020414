import React from "react";
import styles from "./LineWithIcon.module.scss";
import { Edit, Icon } from "iconsax-react";
import { AppIcon } from "common";

interface ILineWithIcon {
    icon: Icon,
    text: string,
    // textPrimary comes after text and has primary color
    textPrimary?: string;
    rotate180deg?: boolean,
    color?: string;
    onClick?: () => void;
}


const LineWithIcon: React.FC<ILineWithIcon> = ({ icon, text, textPrimary, rotate180deg, color, onClick }) => {
    return <>
        <div className={styles.lineWithIcon}>
            <div>
                <AppIcon color={color ? color : "var(--primaryTextColor)"} size={22} icon={icon} rotate180deg={rotate180deg} />
            </div>
            <div style={{ wordBreak: "break-all", whiteSpace: "normal", overflowWrap: "break-word" }}>
                <span>{text}</span><span className={styles.textPrimary}>{textPrimary}</span>
            </div>
            {onClick && <div className={styles.editLine}>
                <Edit
                    color="var(--primaryTextColor)"
                    size={22}
                    onClick={onClick}
                />
            </div>}
        </div>
    </>;
}

export default LineWithIcon;